"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var General_1 = require("@/components/molecules/icons/General");
var Navigations_1 = require("@/lib/constants/Navigations");
var PageEndpoints_1 = require("@/lib/constants/PageEndpoints");
var general_hooks_1 = require("@/lib/hooks/general-hooks");
var utils_1 = require("@/lib/utils");
function SocialActivityNavigation() {
    var _a;
    var pathname = (0, general_hooks_1.usePathname)();
    var isLinkedAccountActive = pathname === PageEndpoints_1.PageEndpoints.linkedAccounts;
    var logoMap = (_a = {},
        _a[PageEndpoints_1.PageEndpoints.socialMedia.facebook] = (0, jsx_runtime_1.jsx)(General_1.FacebookSVGLogo, {}),
        _a[PageEndpoints_1.PageEndpoints.socialMedia.instagram] = (0, jsx_runtime_1.jsx)(General_1.InstagramSVGLogo, {}),
        _a[PageEndpoints_1.PageEndpoints.socialMedia.twitter] = (0, jsx_runtime_1.jsx)(General_1.TwitterSVGLogo, {}),
        _a[PageEndpoints_1.PageEndpoints.socialMedia.youtube] = (0, jsx_runtime_1.jsx)(General_1.YoutubeSVGLogo, {}),
        _a[PageEndpoints_1.PageEndpoints.socialMedia.linkedin] = (0, jsx_runtime_1.jsx)(General_1.LinkedinSVGLogo, {}),
        _a);
    return ((0, jsx_runtime_1.jsxs)("aside", { className: 'tw-hidden tw-min-h-screen tw-w-[260px] tw-bg-background-3 tw-p-3 tw-pt-6 tw-text-text-1 md:tw-block', children: [(0, jsx_runtime_1.jsxs)("section", { className: 'tw-ml-3 tw-flex tw-flex-col tw-gap-1', children: [(0, jsx_runtime_1.jsx)("h3", { className: 'tw-text-sm tw-font-bold tw-text-text-1', children: "Socials" }), (0, jsx_runtime_1.jsx)("p", { className: 'tw-text-xs tw-font-normal tw-leading-5 tw-text-text-4', children: "Participate in social activities to earn more XPs" })] }), (0, jsx_runtime_1.jsx)("section", { className: 'tw-mt-3 tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-1', children: Navigations_1.SOCIAL_ACTIVITY_NAVIGATIONS.map(function (link, idx) {
                    return ((0, jsx_runtime_1.jsxs)("a", { href: "".concat(link.link), "aria-label": 'profile setting link', className: (0, utils_1.cn)('tw-relative tw-flex tw-w-full tw-transform-cpu tw-items-center tw-gap-2 tw-rounded-md tw-px-3 tw-py-2 tw-text-left tw-text-sm tw-font-bold tw-tracking-tight tw-transition-all tw-duration-100 tw-ease-in-out hover:tw-bg-background-5', pathname.includes(link.link) ? 'tw-bg-background-5 !tw-text-accent-1' : '!tw-text-text-4'), children: [link.link && logoMap[link.link], link.text, idx > 2 && ((0, jsx_runtime_1.jsx)("div", { className: 'tw-absolute tw-right-3 tw-top-1/2 -tw-translate-y-1/2 tw-rounded-[2px] tw-bg-[#6A67FF] tw-px-1 tw-text-[8px] tw-font-bold tw-leading-4 tw-tracking-wide tw-text-white', children: "New" }))] }, idx));
                }) }), (0, jsx_runtime_1.jsx)("hr", { className: 'tw-my-3 tw-border-customgrey' }), (0, jsx_runtime_1.jsxs)("a", { href: PageEndpoints_1.PageEndpoints.linkedAccounts, className: (0, utils_1.cn)('tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-p-3 tw-text-sm tw-font-bold tw-tracking-tight tw-transition-all tw-duration-100 tw-ease-in-out hover:tw-bg-background-5', isLinkedAccountActive ? 'tw-bg-background-5 !tw-text-accent-1' : '!tw-text-text-4'), children: [(0, jsx_runtime_1.jsx)(General_1.SettingsGearSVGIcon, { fill: isLinkedAccountActive ? 'var(--accent-1)' : 'var(--text-1)' }), "Linked Accounts"] })] }));
}
exports.default = SocialActivityNavigation;
