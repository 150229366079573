"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var tabs_1 = require("@/components/ui/tabs");
var utils_1 = require("@/lib/utils");
var react_1 = require("react");
var ArrowLeft_1 = require("../../icons/ArrowLeft");
var ArrowRight_1 = require("../../icons/ArrowRight");
var General_1 = require("../../icons/General");
function CustomTabContent(_a) {
    var title = _a.title, className = _a.className, children = _a.children;
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-flex tw-flex-col tw-gap-6', className), children: [(0, jsx_runtime_1.jsx)("h2", { className: 'tw-text-base tw-font-bold tw-text-text-1', children: title }), children] }));
}
function TabContentSection(_a) {
    var header = _a.header, optional = _a.optional, children = _a.children, onAdd = _a.onAdd;
    return ((0, jsx_runtime_1.jsxs)("section", { className: 'tw-flex tw-w-full tw-flex-col tw-gap-2', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-w-full tw-items-center tw-justify-between', children: [(0, jsx_runtime_1.jsxs)("h3", { className: 'tw-flex tw-w-full tw-items-center tw-gap-1.5 tw-text-sm tw-font-bold tw-text-text-1', children: [header, " ", optional && (0, jsx_runtime_1.jsx)("span", { className: 'tw-text-xs tw-font-normal tw-text-text-4', children: "(Optional)" })] }), onAdd ? ((0, jsx_runtime_1.jsx)("button", { onClick: onAdd, "aria-label": 'add btn', children: (0, jsx_runtime_1.jsx)(General_1.AddCircleSVG, { className: 'tw-stroke-text-1 hover:tw-stroke-accent-1' }) })) : null] }), children] }));
}
function BotSettingTabs(_a) {
    var items = _a.items, activeTab = _a.activeTab, onTabChange = _a.onTabChange;
    (0, react_1.useEffect)(function () {
        var _a, _b;
        var activeTabItem = document.getElementById("bot-setting-tab-".concat((_b = (_a = activeTab === null || activeTab === void 0 ? void 0 : activeTab.toLowerCase()) === null || _a === void 0 ? void 0 : _a.split(' ')) === null || _b === void 0 ? void 0 : _b.join('-')));
        activeTabItem === null || activeTabItem === void 0 ? void 0 : activeTabItem.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'center' });
    }, [activeTab]);
    var handleScroll = function (to) {
        var tabsContainer = document.getElementById('bot-setting-tabs-scroll-container');
        if (to === 'start') {
            tabsContainer === null || tabsContainer === void 0 ? void 0 : tabsContainer.scrollTo({ left: 0, behavior: 'smooth' });
        }
        else {
            tabsContainer === null || tabsContainer === void 0 ? void 0 : tabsContainer.scrollTo({ left: tabsContainer === null || tabsContainer === void 0 ? void 0 : tabsContainer.scrollWidth, behavior: 'smooth' });
        }
    };
    return ((0, jsx_runtime_1.jsxs)(tabs_1.Tabs, { defaultValue: activeTab, children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsx)("button", { onClick: function () { return handleScroll('start'); }, children: (0, jsx_runtime_1.jsx)(ArrowLeft_1.ArrowLeft, {}) }), (0, jsx_runtime_1.jsx)("div", { id: 'bot-setting-tabs-scroll-container', className: 'tw-full no-scrollbar tw-h-[34px] tw-overflow-x-auto tw-overflow-y-hidden', children: (0, jsx_runtime_1.jsx)(tabs_1.TabsList, { className: 'tw-gap-2 tw-bg-transparent !tw-p-0', children: items.map(function (item, index) {
                                var _a, _b, _c;
                                return ((0, jsx_runtime_1.jsx)(tabs_1.TabsTrigger, { id: "bot-setting-tab-".concat((_c = (_b = (_a = item.title) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.split(' ')) === null || _c === void 0 ? void 0 : _c.join('-')), role: 'tab', "aria-label": 'tab item', onClick: function () { return onTabChange(item.title); }, value: item.title, className: (0, utils_1.cn)('tw-relative tw-bg-transparent !tw-px-[2px] tw-text-text-3 after:tw-absolute after:tw-bottom-[-2px] after:tw-left-0 after:tw-h-[2px] after:tw-rounded-full after:tw-bg-accent-1 after:tw-text-red-500 hover:tw-text-text-1 data-[state=active]:tw-border-accent-1 data-[state=active]:tw-font-semibold data-[state=active]:tw-text-text-1 data-[state=active]:after:tw-w-full'), children: item.title }, index));
                            }) }) }), (0, jsx_runtime_1.jsx)("button", { onClick: function () { return handleScroll('end'); }, children: (0, jsx_runtime_1.jsx)(ArrowRight_1.ArrowRight, {}) })] }), items.map(function (item, index) { return ((0, jsx_runtime_1.jsx)(tabs_1.TabsContent, { value: item.title, role: 'tabcontent', "aria-label": 'tab content test', className: '!tw-mx-0 !tw-mt-6', children: item.content }, index)); })] }, activeTab));
}
BotSettingTabs.Content = CustomTabContent;
BotSettingTabs.ContentSection = TabContentSection;
exports.default = BotSettingTabs;
