"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var button_1 = require("@/components/ui/button");
var dialog_1 = require("@/components/ui/dialog");
var language_1 = require("@v2/libraries/language");
function DeleteProfileModal(_a) {
    var shouldShow = _a.shouldShow, onClose = _a.onClose, deleteProfileHandler = _a.deleteProfileHandler;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(dialog_1.Dialog, { open: shouldShow, onOpenChange: onClose, children: (0, jsx_runtime_1.jsxs)(dialog_1.DialogContent, { className: "tw-overflow-hidden !tw-p-0", hideClose: true, children: [(0, jsx_runtime_1.jsx)(dialog_1.DialogHeader, { className: "tw-border-b tw-border-customgrey tw-px-6 tw-py-6", children: (0, jsx_runtime_1.jsx)(dialog_1.DialogTitle, { className: "tw-text-left tw-text-lg", children: "Delete User Profile Tab" }) }), (0, jsx_runtime_1.jsx)("div", { className: 'tw-p-4', children: "Are you sure you want to delete the user profile tab?" }), (0, jsx_runtime_1.jsxs)(dialog_1.DialogFooter, { children: [(0, jsx_runtime_1.jsx)(button_1.Button, { variant: "link", onClick: onClose, children: language_1.translations.Cancel }), (0, jsx_runtime_1.jsx)(button_1.Button, { variant: "success", onClick: deleteProfileHandler, children: "Delete" })] })] }) }) }));
}
exports.default = DeleteProfileModal;
