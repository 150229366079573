"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var molecules_1 = require("@/components/molecules");
var utils_1 = require("@/lib/utils");
var react_1 = require("react");
// ✔ create activity card header component
/**
 * ### Activity Card Header Component ###
 * @description This component can render activity card header part.
 * @param { ActivityCardHeaderProps } props - What is activity card header component property?
 * @example
 * <ActivityCardHeader {...ACTIVITY_CARD_HEADER_PROPS} />
 * @version 1.0.0
 * @author Wunna
 */
function ActivityCardHeader(_a) {
    var type = _a.type, reaction = _a.reaction, channel = _a.channel, timestamp = _a.timestamp, isUnread = _a.isUnread, onNavigateChannel = _a.onNavigateChannel;
    var _b = channel || {}, channelId = _b._id, channelType = _b.type, channelName = _b.name, channelIsPrivate = _b.isPrivate;
    // ✔ create navigate channel handler function
    /**
     * ### Navigate Channel Handler Function ###
     * @description This function can navigate channel when user click. ( channel id is already memorized )
     * @version 1.0.0
     * @author Wunna
     */
    var navigateChannelHandler = (0, react_1.useCallback)(function () {
        onNavigateChannel(channelId);
    }, [onNavigateChannel, channelId]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-justify-between tw-text-xs', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-line-clamp-1', children: [(0, jsx_runtime_1.jsx)("span", { className: 'tw-align-top', children: 
                        // for reply type
                        type === 'reply'
                            ? 'Replied in'
                            : // for mention type
                                type === 'mention'
                                    ? 'Mentioned in'
                                    : // for thread type
                                        type === 'thread'
                                            ? 'Thread in'
                                            : // for reaction type
                                                type === 'reaction'
                                                    ? "".concat(reaction.name, " ").concat(reaction.count > 1 ? "and ".concat(reaction.count - 1, " others") : '', " reacted in")
                                                    : type === 'trade-idea'
                                                        ? 'Analysis posted in'
                                                        : '' }), (0, jsx_runtime_1.jsxs)("span", { className: 'tw-ml-1 tw-inline-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-text-text-1', onClick: navigateChannelHandler, children: [(0, jsx_runtime_1.jsx)(molecules_1.ChannelIcon, { type: channelType, isPrivate: channelIsPrivate }), " ", channelName] })] }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsx)("p", { className: 'tw-whitespace-nowrap', children: (0, utils_1.calculateMinutesAgo)(timestamp) }), isUnread && (0, jsx_runtime_1.jsx)("div", { className: 'tw-h-2.5 tw-w-2.5 tw-rounded-full tw-bg-global-notification-red' })] })] }));
}
exports.default = ActivityCardHeader;
