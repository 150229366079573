"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowDownFilled = exports.ArrowUpFilled = exports.ArrowRightFilled = exports.ArrowLeftFilled = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var ArrowLeftFilled = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '16' : _b, _c = _a.height, height = _c === void 0 ? '16' : _c, className = _a.className;
    return ((0, jsx_runtime_1.jsx)("svg", { width: width, height: height, viewBox: '0 0 12 12', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', className: className, children: (0, jsx_runtime_1.jsx)("path", { d: 'M4.50536 6.18333C4.24901 5.92699 4.24901 5.51068 4.50536 5.25433L7.13036 2.62933C7.31903 2.44066 7.59999 2.38529 7.84608 2.48783C8.09217 2.59036 8.25214 2.82826 8.25214 3.09486L8.25214 8.34486C8.25214 8.60941 8.09217 8.84935 7.84608 8.95189C7.59999 9.05443 7.31903 8.99701 7.13036 8.81039L4.50536 6.18538V6.18333Z', fill: 'var(--icon-color-default)' }) }));
};
exports.ArrowLeftFilled = ArrowLeftFilled;
var ArrowRightFilled = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '16' : _b, _c = _a.height, height = _c === void 0 ? '16' : _c, className = _a.className;
    return ((0, jsx_runtime_1.jsx)("svg", { width: width, height: height, viewBox: '0 0 12 12', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', className: className, children: (0, jsx_runtime_1.jsx)("path", { d: 'M8.05989 5.25588C8.31624 5.51222 8.31624 5.92853 8.05989 6.18488L5.43489 8.80988C5.24622 8.99855 4.96526 9.05392 4.71917 8.95138C4.47307 8.84884 4.31311 8.61095 4.31311 8.34435L4.31311 3.09435C4.31311 2.8298 4.47307 2.58986 4.71917 2.48732C4.96526 2.38478 5.24622 2.4422 5.43489 2.62882L8.05989 5.25382V5.25588Z', fill: 'var(--icon-color-default)' }) }));
};
exports.ArrowRightFilled = ArrowRightFilled;
var ArrowUpFilled = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '16' : _b, _c = _a.height, height = _c === void 0 ? '16' : _c, className = _a.className;
    return ((0, jsx_runtime_1.jsx)("svg", { width: width, height: height, viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', className: className, children: (0, jsx_runtime_1.jsx)("path", { d: 'M7.75881 5.25625C8.10061 4.91445 8.65569 4.91445 8.99748 5.25625L12.4975 8.75625C12.749 9.00781 12.8229 9.38242 12.6862 9.71055C12.5494 10.0387 12.2322 10.252 11.8768 10.252L4.87678 10.252C4.52404 10.252 4.20412 10.0387 4.0674 9.71055C3.93069 9.38242 4.00725 9.00781 4.25607 8.75625L7.75608 5.25625H7.75881Z', fill: 'var(--icon-color-default)' }) }));
};
exports.ArrowUpFilled = ArrowUpFilled;
var ArrowDownFilled = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '16' : _b, _c = _a.height, height = _c === void 0 ? '16' : _c, className = _a.className;
    return ((0, jsx_runtime_1.jsx)("svg", { width: width, height: height, viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg', className: className, children: (0, jsx_runtime_1.jsx)("path", { d: 'M8.9951 9.9957C8.6533 10.3375 8.09822 10.3375 7.75642 9.9957L4.25642 6.4957C4.00486 6.24414 3.93103 5.86953 4.06775 5.54141C4.20447 5.21328 4.52166 5 4.87713 5H11.8771C12.2299 5 12.5498 5.21328 12.6865 5.54141C12.8232 5.86953 12.7467 6.24414 12.4978 6.4957L8.99783 9.9957H8.9951Z', fill: 'var(--icon-color-default)' }) }));
};
exports.ArrowDownFilled = ArrowDownFilled;
