"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var utils_1 = require("@/lib/utils");
var General_1 = require("../icons/General");
function InfoCard(_a) {
    var content = _a.content, className = _a.className;
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-bg-background-7 tw-px-[10px] tw-py-2', className), children: [(0, jsx_runtime_1.jsx)(General_1.InfoSVG, { width: 16, height: 16, className: '!tw-shrink-0' }), (0, jsx_runtime_1.jsx)("div", { className: 'tw-text-justify tw-text-xs tw-leading-[18px] tw-text-text-3', children: content })] }));
}
exports.default = InfoCard;
