"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOAST_ID_SOCIAL_ACCOUNT_ERROR = exports.TOAST_ID_SOCIAL_ACCOUNT_SAVE_SUCCESS = exports.TOAST_ID_SOCIAL_ACTIVITY_ERROR = exports.TOAST_ID_SOCIAL_ACTIVITY_UNSAVED_CHANGES = exports.TOAST_ID_SOCIAL_ACTIVITY_SAVE_SUCCESS = exports.COMMENT_CRITERIA_MATCH_TYPE_OPTIONS = exports.BAN_UNIT_OPTIONS = exports.POST_DISPLAY_TYPE_OPTIONS = exports.DEFAULT_DISPLAYED_POST_LIMIT = exports.DEFAULT_COMMENT_SETTINGS = exports.DEFAULT_PENALTY_SETTINGS = exports.DEFAULT_MASTER_REWARD_SETTINGS = exports.DEFAULT_POST_REWARD_SETTINGS = exports.TwitterMasterRewardSetting = exports.TwitterPostRewardSetting = exports.YoutubeMasterRewardSetting = exports.YoutubePostRewardSetting = exports.LinkedInMasterRewardSetting = exports.LinkedInPostRewardSetting = exports.FacebookMasterRewardSetting = exports.FacebookPostRewardSetting = exports.InstagramMasterRewardSetting = exports.InstagramPostRewardSetting = exports.BanUnit = exports.PostDisplayType = exports.CommentCriteriaMatchType = exports.PenaltyAction = exports.RewardAction = exports.SocialPlatform = void 0;
var typo_utils_1 = require("../utils/typo-utils");
/**
 * Social platforms that can be connected to the app.
 */
exports.SocialPlatform = {
    Instagram: 'instagram',
    Facebook: 'facebook',
    Twitter: 'x',
    Youtube: 'youtube',
    // LinkedIn: 'linkedIn',
};
/**
 * Actions that will be rewarded to the user.
 */
exports.RewardAction = {
    Follow: 'follow',
    Like: 'like',
    Comment: 'comment',
    Share: 'share',
};
/**
 * Actions that will be penalized to the user.
 */
exports.PenaltyAction = {
    DeleteComment: 'delete-comment',
    UnlikePost: 'unlike',
    UnfollowAccount: 'unfollow',
};
/**
 * Match types for comment criteria.
 */
exports.CommentCriteriaMatchType = {
    ContainAll: 'contain-all',
    ContainAny: 'contain-any',
    ExactMatch: 'exact-match',
};
/**
 * Display types for social posts.
 */
exports.PostDisplayType = {
    Automatic: 'automatic',
    Custom: 'custom',
};
/**
 * Units for ban duration.
 */
exports.BanUnit = {
    Second: 'second',
    Minute: 'minute',
    Hour: 'hour',
    Day: 'day',
    Week: 'week',
    Month: 'month',
};
//---------- Default Reward Settings ----------//
//---------- Instagram
/**
 * Default reward settings for each instagram post.
 */
exports.InstagramPostRewardSetting = [
    {
        rewardType: exports.RewardAction.Like,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
    {
        rewardType: exports.RewardAction.Comment,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
];
/**
 * Default master reward settings for instagram.
 */
exports.InstagramMasterRewardSetting = __spreadArray([
    {
        rewardType: exports.RewardAction.Follow,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    }
], __read(exports.InstagramPostRewardSetting), false);
//---------- Facebook
/**
 * Default reward settings for each facebook post.
 */
exports.FacebookPostRewardSetting = [
    {
        rewardType: exports.RewardAction.Like,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
    {
        rewardType: exports.RewardAction.Comment,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
];
/**
 * Default master reward settings for facebook.
 */
exports.FacebookMasterRewardSetting = __spreadArray([
    {
        rewardType: exports.RewardAction.Follow,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    }
], __read(exports.FacebookPostRewardSetting), false);
//---------- LinkedIn
/**
 * Default reward settings for each linkedin post.
 */
exports.LinkedInPostRewardSetting = [
    {
        rewardType: exports.RewardAction.Like,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
    {
        rewardType: exports.RewardAction.Comment,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
    {
        rewardType: exports.RewardAction.Share,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
];
/**
 * Default master reward settings for linkedin.
 */
exports.LinkedInMasterRewardSetting = __spreadArray([
    {
        rewardType: exports.RewardAction.Follow,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    }
], __read(exports.LinkedInPostRewardSetting), false);
//---------- Youtube
/**
 * Default reward settings for each youtube post.
 */
exports.YoutubePostRewardSetting = [
    {
        rewardType: exports.RewardAction.Comment,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
];
/**
 * Default master reward settings for youtube.
 */
exports.YoutubeMasterRewardSetting = __spreadArray([], __read(exports.YoutubePostRewardSetting), false);
//---------- Twitter
/**
 * Default reward settings for each twitter post.
 */
exports.TwitterPostRewardSetting = [
    {
        rewardType: exports.RewardAction.Like,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
    {
        rewardType: exports.RewardAction.Comment,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
    {
        rewardType: exports.RewardAction.Share,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    },
];
/**
 * Default master reward settings for twitter.
 */
exports.TwitterMasterRewardSetting = __spreadArray([
    {
        rewardType: exports.RewardAction.Follow,
        enabled: false,
        maxActions: 1,
        xpEarned: 0,
        inGameCurrencyEarned: 0,
    }
], __read(exports.TwitterPostRewardSetting), false);
exports.DEFAULT_POST_REWARD_SETTINGS = (_a = {},
    _a[exports.SocialPlatform.Instagram] = exports.InstagramPostRewardSetting,
    _a[exports.SocialPlatform.Facebook] = exports.FacebookPostRewardSetting,
    _a[exports.SocialPlatform.Youtube] = exports.YoutubePostRewardSetting,
    _a[exports.SocialPlatform.Twitter] = exports.TwitterPostRewardSetting,
    _a);
exports.DEFAULT_MASTER_REWARD_SETTINGS = (_b = {},
    _b[exports.SocialPlatform.Instagram] = exports.InstagramMasterRewardSetting,
    _b[exports.SocialPlatform.Facebook] = exports.FacebookMasterRewardSetting,
    _b[exports.SocialPlatform.Youtube] = exports.YoutubeMasterRewardSetting,
    _b[exports.SocialPlatform.Twitter] = exports.TwitterMasterRewardSetting,
    _b);
exports.DEFAULT_PENALTY_SETTINGS = [
    {
        penaltyType: exports.PenaltyAction.DeleteComment,
        enabled: false,
        maxActions: 1,
        banDuration: 1,
        banUnit: 'day',
    },
    {
        penaltyType: exports.PenaltyAction.UnlikePost,
        enabled: false,
        maxActions: 1,
        banDuration: 1,
        banUnit: 'day',
    },
    {
        penaltyType: exports.PenaltyAction.UnfollowAccount,
        enabled: false,
        maxActions: 1,
        banDuration: 1,
        banUnit: 'day',
    },
];
exports.DEFAULT_COMMENT_SETTINGS = {
    minWordCount: {
        enabled: false,
        value: 0,
    },
    keywords: {
        enabled: false,
        matchType: exports.CommentCriteriaMatchType.ContainAll,
        value: [],
    },
    relevance: {
        enabled: false,
        value: '',
    },
};
exports.DEFAULT_DISPLAYED_POST_LIMIT = 8;
exports.POST_DISPLAY_TYPE_OPTIONS = [
    { text: 'Automatic', value: exports.PostDisplayType.Automatic },
    { text: 'Custom', value: exports.PostDisplayType.Custom },
];
exports.BAN_UNIT_OPTIONS = Object.values(exports.BanUnit).map(function (type) { return ({
    value: type,
    label: (0, typo_utils_1.capitalizeFirstLetter)(type) + '(s)',
}); });
exports.COMMENT_CRITERIA_MATCH_TYPE_OPTIONS = Object.values(exports.CommentCriteriaMatchType).map(function (type) { return ({
    value: type,
    label: type
        .split('-')
        .map(function (word) { return (0, typo_utils_1.capitalizeFirstLetter)(word); })
        .join(' '),
}); });
//---------- Toast IDs ----------//
exports.TOAST_ID_SOCIAL_ACTIVITY_SAVE_SUCCESS = 'social-activity-save-success';
exports.TOAST_ID_SOCIAL_ACTIVITY_UNSAVED_CHANGES = 'social-activity-unsaved-changes';
exports.TOAST_ID_SOCIAL_ACTIVITY_ERROR = 'social-activity-error';
exports.TOAST_ID_SOCIAL_ACCOUNT_SAVE_SUCCESS = 'social-account-save-success';
exports.TOAST_ID_SOCIAL_ACCOUNT_ERROR = 'social-account-error';
