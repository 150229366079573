"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOCIAL_LOGOS = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var Skeletons_1 = require("@/components/atoms/Skeletons");
var Socials_1 = require("@/components/molecules/icons/Socials");
var button_1 = require("@/components/ui/button");
var Socials_2 = require("@/lib/constants/Socials");
exports.SOCIAL_LOGOS = (_a = {},
    _a[Socials_2.SocialPlatform.Facebook] = (0, jsx_runtime_1.jsx)(Socials_1.FacebookSVGLogo, {}),
    _a[Socials_2.SocialPlatform.Instagram] = (0, jsx_runtime_1.jsx)(Socials_1.InstagramSVGLogo, {}),
    _a[Socials_2.SocialPlatform.Twitter] = (0, jsx_runtime_1.jsx)(Socials_1.TwitterSVGLogo, {}),
    _a[Socials_2.SocialPlatform.Youtube] = (0, jsx_runtime_1.jsx)(Socials_1.YoutubeSVGLogo, {}),
    _a);
function SocialConnectCard(_a) {
    var loading = _a.loading, socialType = _a.socialType, title = _a.title, isConnected = _a.isConnected, preview = _a.preview, username = _a.username, onConnect = _a.onConnect, onDisconnect = _a.onDisconnect;
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-w-full tw-flex-col tw-gap-2 tw-rounded-md tw-bg-background-6 tw-px-3 tw-py-2', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-justify-between tw-gap-2 ', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [exports.SOCIAL_LOGOS[socialType], (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-1.5', children: [(0, jsx_runtime_1.jsx)("h3", { className: 'tw-m-0 tw-text-sm tw-font-bold tw-capitalize tw-text-text-1', children: title || socialType }), isConnected && ((0, jsx_runtime_1.jsx)("div", { className: 'tw-rounded-[3px] tw-bg-success-1 tw-px-1 tw-text-[8px] tw-font-bold tw-leading-4 tw-tracking-wide tw-text-white', children: "Connected" }))] }), (0, jsx_runtime_1.jsx)("p", { className: 'tw-line-clamp-1 tw-break-all tw-text-xs tw-font-normal tw-leading-5 tw-text-text-3', children: isConnected ? "".concat(username) : 'No accounts connected' })] })] }), isConnected ? ((0, jsx_runtime_1.jsx)(button_1.Button, { disabled: loading, variant: 'outline', className: '!tw-font-bold !tw-text-accent-1 hover:!tw-bg-transparent hover:!tw-opacity-80', size: 'sm', onClick: onDisconnect, children: "Disconnect" })) : ((0, jsx_runtime_1.jsx)(button_1.Button, { size: 'sm', className: '!tw-font-bold', onClick: onConnect, disabled: loading, children: "Connect" }))] }), isConnected && !!preview && preview] }));
}
var SocialConnectCardSkeleton = function () {
    return ((0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-justify-between tw-gap-2 tw-rounded-sm tw-bg-background-6 tw-px-3 tw-py-3', children: [(0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-items-center tw-gap-2', children: [(0, jsx_runtime_1.jsx)(Skeletons_1.Skeleton, { className: 'tw-h-9 tw-w-9 !tw-rounded-full' }), (0, jsx_runtime_1.jsxs)("div", { className: 'tw-flex tw-flex-col tw-gap-2', children: [(0, jsx_runtime_1.jsx)(Skeletons_1.Skeleton, { className: 'tw-h-4 tw-w-[80px]' }), (0, jsx_runtime_1.jsx)(Skeletons_1.Skeleton, { className: 'tw-h-3 tw-w-[135px]' })] })] }), (0, jsx_runtime_1.jsx)(Skeletons_1.Skeleton, { className: 'tw-h-9 tw-w-[80px]' })] }));
};
SocialConnectCard.displayName = 'SocialConnectCard';
SocialConnectCard.Skeleton = SocialConnectCardSkeleton;
exports.default = SocialConnectCard;
