"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addPostDefaultSettings = exports.convertSocialSettingDtoToInterface = exports.convertProfileDtoToInterface = exports.revertOverriddenSettings = exports.hasOverriddenSettings = void 0;
var Socials_1 = require("../constants/Socials");
var hasOverriddenSettings = function (target, setting) {
    var _a;
    if (target === 'rewardSettings') {
        var postSettings = setting.posts.map(function (post) { return post.rewardSettings; });
        var rewardTypes_1 = ((postSettings === null || postSettings === void 0 ? void 0 : postSettings.length) > 0 && ((_a = postSettings[0]) === null || _a === void 0 ? void 0 : _a.map(function (reward) { return reward.rewardType; }))) || [];
        var masterSetting_1 = setting.rewardSettings.filter(function (reward) { return rewardTypes_1.includes(reward.rewardType); });
        return postSettings.some(function (s) {
            return s.some(function (reward) {
                var m = masterSetting_1.find(function (master) { return master.rewardType === reward.rewardType; });
                if (!m)
                    return false;
                if (m.enabled !== reward.enabled)
                    return true;
                if (m.maxActions !== reward.maxActions)
                    return true;
                if (m.xpEarned !== reward.xpEarned)
                    return true;
                if (m.inGameCurrencyEarned !== reward.inGameCurrencyEarned)
                    return true;
                return false;
            });
        });
    }
    if (target === 'commentSetting') {
        var postSettings = setting.posts.map(function (post) { return post.commentSetting; });
        var masterSetting_2 = setting.commentSetting;
        return postSettings.some(function (s) {
            if (s.minWordCount.enabled !== masterSetting_2.minWordCount.enabled)
                return true;
            if (s.minWordCount.value !== masterSetting_2.minWordCount.value)
                return true;
            if (s.keywords.enabled !== masterSetting_2.keywords.enabled)
                return true;
            if (s.keywords.matchType !== masterSetting_2.keywords.matchType)
                return true;
            if (s.keywords.value.join() !== masterSetting_2.keywords.value.join())
                return true;
            if (s.relevance.enabled !== masterSetting_2.relevance.enabled)
                return true;
            if (s.relevance.value !== masterSetting_2.relevance.value)
                return true;
            return false;
        });
    }
    return false;
};
exports.hasOverriddenSettings = hasOverriddenSettings;
var revertOverriddenSettings = function (target, setting) {
    var _a;
    if (target === 'rewardSettings') {
        var postSettings = setting.posts.map(function (post) { return post.rewardSettings; });
        var rewardTypes_2 = ((postSettings === null || postSettings === void 0 ? void 0 : postSettings.length) > 0 && ((_a = postSettings[0]) === null || _a === void 0 ? void 0 : _a.map(function (reward) { return reward.rewardType; }))) || [];
        var masterSetting_3 = setting.rewardSettings.filter(function (reward) { return rewardTypes_2.includes(reward.rewardType); });
        var newPosts = setting.posts.map(function (post) {
            var newRewardSettings = post.rewardSettings.map(function (reward) {
                var m = masterSetting_3.find(function (master) { return master.rewardType === reward.rewardType; });
                if (!m)
                    return reward;
                return __assign({}, m);
            });
            return __assign(__assign({}, post), { rewardSettings: newRewardSettings });
        });
        return __assign(__assign({}, setting), { posts: newPosts });
    }
    if (target === 'commentSetting') {
        var newPosts = setting.posts.map(function (post) {
            return __assign(__assign({}, post), { commentSetting: __assign({}, setting.commentSetting) });
        });
        return __assign(__assign({}, setting), { posts: newPosts });
    }
    return setting;
};
exports.revertOverriddenSettings = revertOverriddenSettings;
var convertProfileDtoToInterface = function (data) {
    return Object.entries(data)
        .map(function (_a) {
        var _b, _c, _d, _e;
        var _f = __read(_a, 2), key = _f[0], value = _f[1];
        var socialType = key === 'twitter' ? Socials_1.SocialPlatform.Twitter : key;
        if (typeof value !== 'boolean') {
            switch (key) {
                case 'youtube':
                    return {
                        socialType: socialType,
                        name: value.profile.name,
                        picture: value.profile.picture,
                        email: value.profile.email,
                        isConnected: !!value,
                        pages: (value.channels || []).map(function (channel) {
                            var _a, _b;
                            return ({
                                id: channel.channelId,
                                displayName: channel.title,
                                profileImage: (_b = (_a = channel.thumbnails) === null || _a === void 0 ? void 0 : _a.default) === null || _b === void 0 ? void 0 : _b.url,
                            });
                        }),
                        selectedPageId: value.selectedPageId || value.channels[0].channelId || '',
                    };
                case 'twitter':
                    return {
                        socialType: socialType,
                        name: value.name,
                        picture: value.profile_image_url,
                        email: value.username,
                        isConnected: !!value,
                        pages: [
                            {
                                id: value.id,
                                displayName: value.name,
                                profileImage: value.profile_image_url,
                            },
                        ],
                        selectedPageId: value.selectedPageId || value.id || '',
                    };
                case 'facebook':
                    return {
                        socialType: socialType,
                        name: (_b = value.profile) === null || _b === void 0 ? void 0 : _b.name,
                        picture: (_c = value.profile) === null || _c === void 0 ? void 0 : _c.picture,
                        email: (_d = value.profile) === null || _d === void 0 ? void 0 : _d.email,
                        isConnected: !!value,
                        pages: (value.pages || []).map(function (page) { return ({
                            id: page.pageId,
                            displayName: page.name,
                            profileImage: page.picture,
                        }); }),
                        selectedPageId: value.selectedPageId || ((_e = value.pages[0]) === null || _e === void 0 ? void 0 : _e.pageId) || '',
                    };
                case 'instagram':
                    return {
                        socialType: socialType,
                        name: value.profile.name,
                        picture: value.profile.picture,
                        email: value.profile.name,
                        isConnected: !!value,
                        pages: [
                            {
                                id: value.profile.id,
                                displayName: value.profile.name,
                                profileImage: value.profile.picture,
                            },
                        ],
                        selectedPageId: value.selectedPageId || value.profile.id || '',
                    };
            }
        }
        // Empty social account
        return {
            socialType: socialType,
            name: '',
            picture: '',
            email: '',
            isConnected: false,
            pages: [],
            selectedPageId: '',
        };
    })
        .filter(Boolean);
};
exports.convertProfileDtoToInterface = convertProfileDtoToInterface;
var convertSocialSettingDtoToInterface = function (data) {
    return data.map(function (setting) {
        var _a;
        return (__assign(__assign({}, setting), { socialType: setting.socialType === 'twitter' ? 'x' : setting.socialType, posts: (setting.posts || [])
                // Order the posts by the order field
                .sort(function (a, b) { return (a === null || a === void 0 ? void 0 : a.order) - (b === null || b === void 0 ? void 0 : b.order); })
                // Add default settings if the post does not have any settings
                .map(function (post) {
                var _a, _b, _c, _d, _e;
                return __assign(__assign({}, post), { rewardSettings: ((_a = post.rewardSettings) === null || _a === void 0 ? void 0 : _a.length) > 0
                        ? post.rewardSettings
                        : Socials_1.DEFAULT_POST_REWARD_SETTINGS[setting.socialType === 'twitter' ? 'x' : setting.socialType], commentSetting: post.commentSetting
                        ? __assign(__assign({}, post.commentSetting), { keywords: __assign(__assign({}, post.commentSetting.keywords), { value: ((_b = post.commentSetting.keywords) === null || _b === void 0 ? void 0 : _b.value) || [], enabled: ((_c = post.commentSetting.keywords) === null || _c === void 0 ? void 0 : _c.enabled) || false }), relevance: __assign(__assign({}, post.commentSetting.relevance), { enabled: ((_d = post.commentSetting.relevance) === null || _d === void 0 ? void 0 : _d.enabled) || false }), minWordCount: __assign(__assign({}, post.commentSetting.minWordCount), { enabled: ((_e = post.commentSetting.minWordCount) === null || _e === void 0 ? void 0 : _e.enabled) || false }) }) : Socials_1.DEFAULT_COMMENT_SETTINGS });
            }), 
            // Add default master settings if the setting does not have any settings
            rewardSettings: ((_a = setting.rewardSettings) === null || _a === void 0 ? void 0 : _a.length) > 0
                ? setting.rewardSettings
                : Socials_1.DEFAULT_MASTER_REWARD_SETTINGS[setting.socialType === 'twitter' ? 'x' : setting.socialType], penaltySettings: setting.penaltySettings.length > 0 ? setting.penaltySettings : Socials_1.DEFAULT_PENALTY_SETTINGS, commentSetting: setting.commentSetting || Socials_1.DEFAULT_COMMENT_SETTINGS }));
    });
};
exports.convertSocialSettingDtoToInterface = convertSocialSettingDtoToInterface;
var addPostDefaultSettings = function (posts, socialType) {
    return posts.map(function (post) {
        var _a, _b, _c, _d, _e;
        return __assign(__assign({}, post), { 
            // Add default settings if the post does not have any settings
            rewardSettings: ((_a = post.rewardSettings) === null || _a === void 0 ? void 0 : _a.length) > 0 ? post.rewardSettings : Socials_1.DEFAULT_POST_REWARD_SETTINGS[socialType], commentSetting: post.commentSetting
                ? __assign(__assign({}, post.commentSetting), { keywords: __assign(__assign({}, post.commentSetting.keywords), { value: ((_b = post.commentSetting.keywords) === null || _b === void 0 ? void 0 : _b.value) || [], enabled: ((_c = post.commentSetting.keywords) === null || _c === void 0 ? void 0 : _c.enabled) || false }), relevance: __assign(__assign({}, post.commentSetting.relevance), { enabled: ((_d = post.commentSetting.relevance) === null || _d === void 0 ? void 0 : _d.enabled) || false }), minWordCount: __assign(__assign({}, post.commentSetting.minWordCount), { enabled: ((_e = post.commentSetting.minWordCount) === null || _e === void 0 ? void 0 : _e.enabled) || false }) }) : Socials_1.DEFAULT_COMMENT_SETTINGS });
    });
};
exports.addPostDefaultSettings = addPostDefaultSettings;
