"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var select_1 = require("@/components/ui/select");
var utils_1 = require("@/lib/utils");
/**
 * Select box component
 * @description Example usage can be found at `BadgeFormModal.tsx`
 */
function SelectBox(_a) {
    var _b;
    var options = _a.options, value = _a.value, onChange = _a.onChange, label = _a.label, name = _a.name, _c = _a.placeholder, placeholder = _c === void 0 ? 'Select' : _c, maxHeight = _a.maxHeight, disabled = _a.disabled, triggerClassNames = _a.triggerClassNames, defaultValue = _a.defaultValue, hideCheck = _a.hideCheck, ghost = _a.ghost, containerClassNames = _a.containerClassNames, align = _a.align;
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.cn)('tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-1', containerClassNames), children: [label && ((0, jsx_runtime_1.jsx)("label", { className: 'tw-text-sm tw-font-[500] tw-text-text-1', htmlFor: name, children: label })), (0, jsx_runtime_1.jsxs)(select_1.Select, { disabled: disabled, value: value ? value : '', onValueChange: onChange, defaultValue: defaultValue, children: [(0, jsx_runtime_1.jsx)(select_1.SelectTrigger, { ghost: ghost, className: (0, utils_1.cn)('tw-w-full tw-truncate tw-whitespace-nowrap', triggerClassNames), children: (0, jsx_runtime_1.jsx)(select_1.SelectValue, { placeholder: placeholder, children: !value ? ((0, jsx_runtime_1.jsx)("span", { className: 'placeholder', children: placeholder })) : ((_b = options.find(function (opt) { return opt.value.toLowerCase() === value.toLowerCase(); })) === null || _b === void 0 ? void 0 : _b.label) }) }), (0, jsx_runtime_1.jsx)(select_1.SelectContent, { className: (0, utils_1.cn)('tw-shadow-lg'), align: align, children: (0, jsx_runtime_1.jsx)(select_1.SelectGroup, { className: (0, utils_1.cn)('tw-overflow-y-auto', options.length === 0 ? 'tw-h-fit' : maxHeight ? 'tw-h-[149px]' : 'tw-h-fit'), children: options.map(function (option) { return ((0, jsx_runtime_1.jsx)(select_1.SelectItem, { value: option.value, hideCheck: hideCheck, ghost: ghost, children: option.label }, option.value)); }) }) })] })] }));
}
exports.default = SelectBox;
