"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var calendar_1 = require("@/components/ui/calendar");
var popover_1 = require("@/components/ui/popover");
/**
 * Single DatePicker
 */
function DatePicker(_a) {
    var date = _a.date, setDate = _a.setDate, disablePastDays = _a.disablePastDays, children = _a.children;
    return ((0, jsx_runtime_1.jsxs)(popover_1.Popover, { children: [(0, jsx_runtime_1.jsx)(popover_1.PopoverTrigger, { asChild: true, children: children }), (0, jsx_runtime_1.jsx)(popover_1.PopoverContent, { className: 'tw-w-auto', children: (0, jsx_runtime_1.jsx)(calendar_1.Calendar, { mode: 'single', selected: date, onSelect: setDate, defaultMonth: date, initialFocus: true, disabled: disablePastDays ? [{ before: new Date() }] : undefined }) })] }));
}
exports.default = DatePicker;
