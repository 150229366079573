"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var language_provider_1 = require("./language-provider");
// ✔ create use language hook
var useLanguage = function () {
    var context = (0, react_1.useContext)(language_provider_1.LanguageContext);
    if (!context)
        throw new Error('useLanguage must be used within a LanguageProvider');
    return context;
};
exports.default = useLanguage;
