"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageEndpoints = void 0;
/**
 * Page Endpoints for the Entire Platform
 * @description Please use `PageEndpoint` when you have to integrate `<Link/>` component or `routing` functions.
 */
exports.PageEndpoints = {
    // Profile settings
    account: {
        myProfile: '/app/myaccount/my-profile',
        socialMediaLinks: '/app/myaccount/social-media-links',
        changePassword: '/app/myaccount/change-password',
        payment: '/app/myaccount/payment',
        privacySafety: '/app/myaccount/privacy-safety',
        notificationSettings: '/app/myaccount/notifications-settings',
    },
    usermanager: {
        createUser: '/app/user-manager/create-user',
    },
    // Social Media Linked Accounts
    linkedAccounts: '/app/linked-accounts',
    socialMedia: {
        facebook: '/app/linked-accounts/facebook',
        twitter: '/app/linked-accounts/twitter',
        instagram: '/app/linked-accounts/instagram',
        youtube: '/app/linked-accounts/youtube',
        linkedin: '/app/linked-accounts/linkedin',
    },
};
