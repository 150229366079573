"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTranslations = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var defaultTranslations = {
    Quick_Start: 'Quick Start Guide',
    Community: 'Community',
    Channels: 'Channels',
    Roles: 'Roles',
    Welcome_Setup: 'Welcome Setup',
    Banners: 'Banners',
    Content_Moderation: 'Content Moderation',
    Appearance: 'Appearance',
    Lanaguge: 'Language',
    Language_Settings: 'Language Settings',
    Domain_Email: 'Domain and Email',
    Emoji: 'Emoji',
    Request_Management: 'Request Management',
    User_List: 'User List',
    Misconduct: 'Misconduct',
    Login_Management: 'Login Management',
    Login_API: 'Login API',
    Actions_Xp: 'Actions and XP',
    XP_Reward_Configuration: 'XP & Reward Configuration',
    Tiers_Xp: 'Tiers & Levels',
    Badges: 'Badges',
    Badge: 'Badge',
    Provide_img_format: 'Provide a .PNG or .JPG',
    Badge_Requests: 'Badge Management',
    Badge_R_Description: 'Review and manage members’ badges here',
    Overview: 'Overview',
    Messages: 'Messages',
    Member_Drilldown: 'Member Drilldown',
    Channel_Drilldown: 'Channel Drilldown',
    Privacy_Policy: 'Privacy Policy',
    Terms_Service: 'Terms of Service',
    Audit_Log: 'Audit Log',
    Delete_Server: 'Delete Community',
    Delete_Channel: 'Delete Channel',
    Be_Careful_Description: 'Careful! You have unsaved changes',
    Delete_Image: 'Delete Image',
    PERSONALISATION: 'Personalization',
    USER_MANAGEMENT: 'User Management',
    Gamification: 'Gamification',
    SERVER_ANALYTICS: 'SERVER ANALYTICS',
    COMMUNITY_ANALYTICS: 'COMMUNITY ANALYTICS',
    COMPLIANCE: 'COMPLIANCE',
    Users: 'Users',
    Search: 'Search',
    Type_Here: 'Type here',
    View_Leaderboard: 'Leaderboard',
    Community_Settings: 'Community Settings',
    Community_Pledge: 'Community Pledge',
    Community_Billing: 'Community Billing',
    Invite_Others: 'Invite Others',
    Invite_Users: 'Invite Users',
    Leave_Server: 'Leave Community',
    leave: 'leave',
    Join: 'Join',
    delete: 'delete',
    light: 'Light',
    dark: 'Dark',
    Size: 'Size',
    Customisation: 'Customisation',
    Button: 'Button',
    Button_Text: 'Button Text',
    Button_Text_Placeholder: 'Input button text here...',
    Url_Link: 'URL Link',
    Url_Link_Placeholder: 'Input url link here...',
    Copy_Widget_Code: 'Copy Widget Code',
    Widget_Preview: 'Widget Preview',
    Charts: 'Charts',
    Enable_Charts: 'Enable Charts',
    Enable_Charts_Description: 'Enabling this would allow charts to be customisable and shown on your channel.',
    Enable_Analysis_Sharing: 'Enable Analysis Sharing',
    Enable_Analysis_Sharing_Description: 'Enabling this will allow users the option of having a shareable link for their analyses.',
    Enable_Analysis_Preview: 'Enable Analysis Preview',
    Enable_Analysis_Preview_Description: 'Allow guests to preview all analysis in the channel without signing in.',
    Chart_Selected: 'Chart Selected',
    Charts_Selected: 'Charts Selected',
    All: 'All',
    Select_All_Label: 'Select / Deselect all in this column',
    Add_Members_Roles: 'Add Members or Roles',
    Search_roles_placeholder: 'Search roles or @username',
    Search_Role: 'Search roles',
    Edit_Channel_Permissions: 'Edit Channel Permissions',
    NO_ROLES_AVAILABLE: 'NO ROLES AVAILABLE',
    NO_USERS_AVAILABLE: 'NO USERS AVAILABLE',
    Remove_User: 'Remove User',
    Remove_Role: 'Remove Role',
    Enter_embed_URL_here: 'Enter embed URL here...',
    Theme: 'Theme',
    General_Invite_Link: 'General Invite Link',
    Personal_Invite_Link: 'Personal Invite Link',
    Invited_Friends: 'Invited Friends',
    Earn_badges: 'Earn badges when you invite friends!',
    Invites: 'invites',
    Language: 'Language',
    Translation: 'Translation',
    Translation_Writing: 'What language will you be writing in?',
    Translation_Note: 'Note that your messages will be translated in real-time and there will be a slight lag whenever you send messages',
    Translation_All_To: 'Translate all messages in this community to:',
    Select_Language: 'Select your default language',
    Translate_Content: 'Translate Message Content',
    Create_Community: 'Create a Community',
    Give_Community_Name: 'Give your community a new name and icon. You can always edit this later',
    Upload: 'UPLOAD',
    Name: 'Name',
    Type_Community_Placeholder: 'Type your community name here (max. 25 characters)',
    Create_Community_Agree_Checkbox: 'By creating a community, I agree to the',
    Community_Terms: 'Community Operator Terms',
    Create: 'Create',
    SETTINGS: 'SETTINGS',
    My_Profile: 'My Profile',
    Social_Media_Links: 'Social Media Links',
    Change_Password: 'Change Password',
    Payment: 'Payment',
    Privacy_Safety: 'Privacy & Safety',
    Notifications: 'Notifications',
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
    Logout: 'LOG OUT',
    Profile_Photo: 'Profile Photo',
    Upload_Image: 'Upload Image',
    Replace_Image: 'Replace Image',
    Remove_Image: 'Remove Image',
    Image_Size_512: 'Maximum image size is 512 x 512 px',
    Min_Image_Size_512: 'Minimum image size is 512 x 512 px',
    Min_Image_Size_320: 'Minimum image size of 320px by 240px',
    Image_Size_1536: 'Maximum image size is 1536 x 512 px. PNG is recommended. ',
    Image_Size_1080: 'Maximum image size is 1920 x 1080 px',
    Max_File_Size_5: 'Maximum file size is 5mb.',
    Upload_Picture: 'Upload Picture',
    Username: 'Username',
    Display_Name: 'Display Name',
    First_Name: 'First Name',
    Last_Name: 'Last Name',
    Email: 'Email',
    Email_Address: 'Email Address',
    Phone_Number: 'Phone Number',
    Country: 'Country',
    Bio: 'Bio',
    Required: 'required',
    Personal_Quote: 'Personal Quote',
    Social_Media_Verify: 'Enter and verify social media links to display them on your profile',
    Password: 'Password',
    Current_Password: 'Current Password',
    No_Payment: 'No payment method saved',
    Payment_Save: 'Check out faster by saving a payment method',
    Add_Card: 'Add Card',
    Reset: 'Reset',
    Save_Changes: 'Save Changes',
    Platform_Privacy: 'PLATFORM PRIVACY',
    TOS: 'Terms of Service',
    PP: 'Privacy Policy',
    Learn: 'Learn more about our',
    Data_Platform_Improve: 'Use data to improve the platform',
    Data_Platform_Improve_Description: 'This setting allows us to use and process information about how you navigate and use the platform for analytical purposes to improve your experience.',
    Data_Platform_Personalise: 'Use data to personalise my experience',
    Data_Platform_Personalise_Description: 'This setting allows us to use information to personalise the platform for you.',
    Data_Platform_Mobile_Number: 'Share my mobile number with the platform for account recovery purposes',
    Data_Platform_Mobile_Number_Description: 'By adding your mobile number, you can reset your account password using the registered number in the event you do not have access to your email account.',
    Data_Platform_Marketing: 'Share my data with the platform to be used for marketing purposes',
    Data_Platform_Marketing_Description: 'This setting allows the platform to keep you updated with news, events and offers.',
    Data_Platform_Safety: 'Use data to promote privacy, safety and integrity',
    Data_Platform_Safety_Description: 'We use the data that we have to verify accounts and activity, combat harmful conduct, prevent spam and other bad experiences and maintain the integrity of our Product(s). For example, we use the data to investigate suspicious activity or violations of our Terms and Policies.',
    Data_Platform_Service: 'Allow the use of my data to deliver the software service and its functionality.',
    Data_Platform_Service_Description: 'These are some necessary cookies that are required to enable the basic features of this software, such as providing you a secure log-in environment, allowing you control to adjust your consent to preferences of data being shared and displaying to you the correct data and servers that your account is linked to. Without these, the Genesiv software will not be able to function.',
    Notifications_How: 'How will you receive notifications',
    Notifications_Push: 'Push Notifications',
    Notifications_Push_Description: 'No push notifications will be sent to you',
    Notifications_Push_Alert: 'Alert me with all push notifications (except the ones you toggled off below)',
    Notifications_Push_Play: 'Play a notification alert sound when receiving a notification',
    Notifications_Push_Enable: 'Enable desktop push notifications',
    Notifications_Email: 'Email Notifications',
    Notifications_Email_Description: 'No email notifications will be sent to you',
    Notifications_Email_Send: 'Send notification emails (except the ones you toggled off)',
    Notifications_What: 'What notifications you will receive',
    Notifications_Mentions: 'Mentions',
    Notifications_Mentions_Description: 'Notifications will be sent to you when someone @mentions you',
    Notifications_Comments: 'Comments',
    Notifications_Comments_Description: 'Notifications will be sent to you when someone comments on your analysis.',
    Notifications_Replies: 'Replies',
    Notifications_Replies_Description: 'Notifications will be sent to you when someone replies to your message or post.',
    Notifications_Reactions: 'Reactions',
    Notifications_Reactions_Description: 'Notifications will be sent to you when someone reacts to your message or post.',
    Notifications_Requests: 'Requests',
    Notifications_Requests_Description: 'Notifications will be sent to you if there is any channel/community requests',
    Notifications_DM: 'Direct Messages',
    Notifications_DM_Description: 'Notifications will be sent to you when someone direct messages you.',
    QS_Launch: 'Launch Checklist',
    QS_Launch_Description: 'This guide will help you set up your community for launch',
    QS_Getting_Started: 'Getting Started',
    QS_Steps_Complete: 'Steps Complete',
    QS_Create_Channel: 'Create a Channel and Send a Message',
    QS_Create_Channel_P1: 'Community created! What’s next? Setting up some channels, of course! What’s a community without spaces for them to have intense discussions or sharing sessions?',
    QS_Create_Channel_P2: 'To create a channel, head over to the “Channels’ page and click on the ‘+’ to create your shiny new channel.',
    QS_Create_Channel_P3: 'Next, give your channel a name! Once you’re done with that, you can choose to save your changes, or continue to customize the channel according to your community needs!',
    QS_Create_Channel_Btn: 'Create Channel',
    QS_Mark_Done_Btn: 'Mark as Done',
    QS_Mark_Undone_Btn: 'Mark as Undone',
    QS_Create_DM: 'Create a Direct Message(DM) Channel',
    QS_Create_DM_P1: 'Psst psst- Wanna create channels where your members can have one-to-one discussions with your team? Well, that’s what DM channels are for! It allows community members to reach out to selected team members (e.g instructors, the product team or customer support, etc.) if they have any questions!',
    QS_Create_DM_P2: 'Let’s create one so you can find out for yourself how it works! First, create a channel, give it a name and then under ‘Type of Channel’ select ‘Direct Message’ and proceed to save your changes.',
    QS_Create_DM_Btn: 'Create DM Channel',
    PYC_Title: 'Personalise Your Community',
    PYC_Appearance: 'Customise the Appearance',
    PYC_Appearance_A_Title: 'Community Colors',
    PYC_Appearance_A_P1: 'Looking to fancy up your community with some theme colours? Here’s how you do it!',
    PYC_Appearance_A_P2: 'Head over to ‘Appearance’ and either choose one of our available colour themes or click on ‘Create Community Colours’',
    PYC_Appearance_A_P3: 'To create your own server colours, you can either input the ‘RGBA’ or ‘HEXA’ codes for the colours you want or just move the colour sliders around till you get your desired colours!',
    PYC_Appearance_A_P4: 'Have fun with it on the preview theme page and achieve your dream theme easily!',
    PYC_Appearance_Theme_Btn: 'Select Theme',
    PYC_Appearance_B_Title: 'Invite Image',
    PYC_Appearance_B_P1: 'Wanna customise the background of your invite page? Well, you’ve come to the right place! Let’s set it up, shall we?',
    PYC_Appearance_B_P2: 'Under ‘Appearance’ in Server Settings, look for ‘Invite Image’ and proceed to click on ‘Upload Image’, choose the image that you want and this will be your background image for your invite page!',
    PYC_Appearance_B_P3: 'Do take note that the minimum size for the image is 1920 x 1080 pixels and the maximum file size is 5 MB.',
    PYC_Appearance_B_Invite_Btn: 'Upload Invite Image',
    PYC_Domain_Title: 'Customise Your Domain',
    PYC_Domain_Description_P1: 'If you’d like to enhance your personal brand, you might want to use a custom domain as your URL! For example, it could be',
    PYC_Domain_Description_P2: 'To do this, head over to the Domain settings page and type in your custom domain name and follow the instructions to authenticate it. You’ll need to go to your domain provider (eg. GoDaddy or CloudFlare) and create the A record in your DNS setting.',
    PYC_Domain_Description_P3: 'Finally, click on “Click Here to Verify” and you’re ready to go!',
    PYC_Domain_Setup_Btn: 'Set Up Domain',
    PYC_Email_Title: 'Configure Your Email',
    PYC_Email_Description_P1: 'For a better community brand experience, send community-related emails, such as those for notifications and transactions from your own email address.',
    PYC_Email_Description_P2: 'To set this up, head over to the Email page. We currently support SMTP and Sendgrid!',
    PYC_Email_Btn: 'Email Configuration',
    CA_Title: 'Controlling Access',
    CA_Roles: 'Create Roles',
    CA_Roles_Description_P1: 'To control access, create different roles to determine what actions users are allowed to carry out and what content they are allowed to see!',
    CA_Roles_Description_P2: "Go to the Roles page and click on the '+' button - a new role will be created. Name your role, select your role colour, and review the role's permissions before clicking 'Save Changes'.",
    CA_Roles_Assign: 'Assign Roles to Members',
    CA_Roles_Assign_Description_P1: 'It’s now time to assign roles to members of your community and determine who gets which role!',
    CA_Roles_Assign_Description_P2: 'Go to the User List page where you can view all your members. Here, you can easily assign and remove roles from users.',
    CA_Roles_Assign_Description_P3: 'Simply click on the (+) icon under the “Roles” column and select roles for your members. To remove a role, click on the “X” beside it.',
    CA_Roles_Assign_Btn: 'Assign Role',
    CA_Roles_Category: 'Assign Roles to a Category',
    CA_Roles_Category_Description_P1: 'If you would like to have private categories consisting of exclusive channels, for example a VIP category where only VIPs can access the channels, here’s what you can do!',
    CA_Roles_Category_Description_P2: 'First, you have to make your category private so go to your category and toggle on ‘Private Category’. Next, click on the (+) icon and assign the roles that you would like to allow access to the category.',
    CA_Roles_Category_Description_P3: 'Click on ‘Done’, save your changes and you have yourself a private category with restricted access!',
    CA_Roles_Category_Btn: 'Assign Roles to Category',
    CA_Private_Channel: 'Create a Private Channel',
    CA_Private_Channel_Description_P1: 'Wanna create an exclusive channel for dedicated users or roles? That’s what private channels are for! Let’s create one now!',
    CA_Private_Channel_Description_P2: 'Go to ‘Channels’ and create a new channel by clicking on the (+) icon, give it a channel name and under ‘Channel Options’ select ‘Private’ and your channel is now a private channel!',
    CA_Private_Channel_Description_P3: 'Allow selected roles/users to view and request for access to this channel.',
    CA_Private_Channel_Btn: 'Create Private Channel',
    CA_Roles_Private: 'Assign Roles to a Private Channel',
    CA_Roles_Private_Description_P1: 'Time to assign roles to the private channel. This means that only these roles will be able to access the private channel and users who aren’t assigned to these roles will have no access.',
    CA_Roles_Private_Description_P2: 'This is useful if you intend to have a ‘VIP’ channel or an ‘Exclusive’ channel with extra perks for qualifying users!',
    CA_Roles_Private_Description_P3: 'In the ‘Channels’ page, go to ‘Role Permissions’ and proceed to assign the roles that are allowed access to the private channel by clicking the (+) button.',
    CA_Roles_Private_Description_P4: 'Choose the role that you would like to access from the pop-up, click ‘Done’ and proceed to save your changes!',
    CA_Required_Attachment_Description: 'Users must upload a file when requesting for access.',
    CA_Required_Attachment_Description_P1: 'Channel Request Description',
    CA_Required_Attachment_Description_P2: 'This appears when users request access to your channel. You can edit the default description below.',
    CA_Roles_Private_Btn: 'Assign Roles to Private Channels',
    CA_Request_Channel: 'Request Channel Access',
    CA_Billing_Channel: 'Billing Access',
    CA_Request_Channel_Description_P1: 'If you require certain channels to have prerequisites before your members are allowed into the channel, for example, an invoice for a certain product needs to be provided before they are allowed channel access, here’s how you can do it.',
    CA_Request_Channel_Description_P2: 'First, go to the ‘Channel’ page, look for the channel that you will require your members to request access to. Next, toggle on ‘Request Channel Access’, next choose the roles that are required to request access.',
    CA_Request_Channel_Description_P3: 'If you require attachments, make sure you turn on ‘Require Attachments’ as well!',
    CA_Billing_Channel_Description_P1: 'Permit roles to only be able to view and purchase roles to access channels.',
    Require_Attachments: 'Require Attachments',
    CA_Request_Channel_Btn: 'Toggle on Request Channel Access',
    CA_Request_Management: 'Request Management',
    CA_Request_Management_Description_P1: 'To allow your community members into the channel, you’ll need to go through their requests and then process to give permission.',
    CA_Request_Management_Description_P2: 'To look at their requests, go to the Request Management page and under ‘Pending Requests’ you can go through the requests and allow access to whoever is deemed worthy.',
    CA_Request_Management_Btn: 'Go to Request Management',
    AC_Title: 'Advanced Checklist',
    AC_Description: 'Explore more advanced features for your community',
    AC_Integrating_Content: 'Integrating Content',
    AC_Embed_Content: 'Embed Content in a channel',
    AC_Embed_Description_P1: 'Wanna embed content such as a video, or even a website? Well, let’s get it done right now!',
    AC_Embed_Description_P2: 'First, go to the Channels page and toggle on ‘Enable iFrame and Embeds’. Next, go to the source that you wish to embed and copy the URL or ‘embed’ code and paste it into the textbox provided and you’ve got yourself some embedded content!',
    AC_Embed_Btn: 'Embed Content',
    AC_Widgets: 'Widgets',
    AC_Widgets_Description_P1: 'Would you like to be able to share your community on other platforms and have others take a peek into your channels and what your community’s about? Widgets will be a great tool for you then!',
    AC_Widgets_Description_P2: 'To enable them, go to the Channels page and toggle on ‘Enable Widget’ and proceed to choose a theme colour as well as the size of your widget.',
    AC_Widgets_Description_P3: 'Next, copy your widget code and paste it in a container on other platforms and you’re good to go!',
    AC_Widgets_Btn: 'Enable Widgets',
    AC_Webhooks: 'Set up Webhooks',
    AC_Webhooks_A_Title: 'Set up Incoming Webhooks',
    AC_Webhooks_A_Description_P1: 'Need some incoming webhooks for your community? We’ve got ya!',
    AC_Webhooks_A_Description_P2: 'Click on ‘Copy URL’ and paste it wherever you wanna get your content from and then click ‘Catch’ to get your content.',
    AC_Webhooks_Incoming_Btn: 'Create Incoming Webhooks',
    AC_Webhooks_B_Title: 'Set up Outgoing Webhooks',
    AC_Webhooks_B_Description_P1: 'Do you need some outgoing webhooks too? Sure, let’s create one now! First, go to the ‘Integration’ page and choose (+) at the ‘Outgoing’ webhooks. Next, choose a name for your webhook. Next you have to get a request url, This is where the data will be sent from your webhook.',
    AC_Webhooks_B_Description_P2: 'To secure your webhook, you need to define a secret token. Choose your request format as well as when you want your webhook to be triggered.',
    AC_Webhooks_B_Description_P3: 'Last but not least, choose the channel(s) that you want your webhook to monitor and then proceed to save your changes.',
    AC_Webhooks_B_Description_P4: 'There you go! One outgoing webhook all nicely set up!',
    AC_Webhooks_Outgoing_Btn: 'Create Outgoing Webhook',
    Compliance: 'Compliance',
    Compliance_Rules: 'Set up Rules and Disclaimers',
    Compliance_Rules_Description: 'To set up rules and disclaimers for your community, go to ‘Welcome Setup’, under ‘Rules and Disclaimers’, click on ‘Set up Rules and Disclaimers’ and you can proceed to either make your own rules and disclaimers or pick from some of the one provided.',
    Compliance_Rules_Btn: 'Set Rules and Disclaimers',
    Compliance_PP: 'Create Custom Privacy Policy',
    Compliance_PP_Description_P1: 'If you need to create a custom privacy policy for your community, here’s what to do!',
    Compliance_PP_Description_P2: 'First, go to ‘Privacy Policy’ and in the text box under ‘Custom Privacy Policy’ fill in your own policy and proceed to save your changes and you’re all good to go!',
    Compliance_PP_Description_P3: 'Next, copy your widget code and paste it in a container on other platforms and you’re good to go!',
    Compliance_PP_Btn: 'Create Custom Privacy Policy',
    Custom_PP: 'Custom Privacy Policy',
    Custom_TOS: 'Custom Terms Of Service',
    Custom_PP_Input: 'Input your own custom Privacy Policy',
    Custom_TOS_Input: 'Input your own custom Terms Of Office',
    Compliance_TOS: 'Create Custom Terms Of Service',
    Input_your_text_here: 'Input your text here.',
    PP_later_desc: 'It will be shown after your custom Privacy Policy. This cannot be edited.',
    TOS_later_desc: 'It will be shown after your custom Terms Of Service. This cannot be edited.',
    Compliance_TOS_Description_P1: 'Need to customise your own Terms of Service? Well, here’s how you do it!',
    Compliance_TOS_Description_P2: 'Go to ‘Terms of Service’ and fill in your own Terms of Service, save your changes and done!',
    Compliance_TOS_Btn: 'Create Custom Terms Of Service',
    Compliance_Moderation: 'Set up Content Moderation',
    Compliance_Moderation_A_Title: 'Profanities',
    Compliance_Moderation_A_Description: 'Content moderation is a way for you to protect your community! Toggle the button on to turn on the profanity filter. You can also click on “Edit Profanity List” to exclude or add words in the list! Users will not be able to send words from the list in your community.',
    Compliance_Moderation_Profanity_Btn: 'Set up Profanity Filter',
    Compliance_Moderation_B_Title: 'Banned Words',
    Compliance_Moderation_B_Description: 'Banned words are similar and can be used to prevent users from sending certain terms, for example competitor brands and products. Simply add words, separated by commas in the “Banned Words” field.',
    Compliance_Moderation_Banned_Btn: 'Set up Banned Words',
    Compliance_Moderation_C_Title: 'Alert Words',
    Compliance_Moderation_C_Description: 'To receive alert logs when certain words are used, add words in the “Alert Words” field and select a channel to send your log to. Only you and your server admins will be able to see the logs, but it is recommended to use a private channel for this!',
    Compliance_Moderation_Alert_Btn: 'Set up Alert Words',
    Engage_Community: 'Engage Your Community',
    Engage_Banner: 'Create a Banner',
    Engage_Description_P1: 'Banners are a great tool to let your community know about various things such as your latest products, new promo codes and much more!',
    Engage_Description_P2: 'To create a banner, go over to the ‘Banners’ page and click on the (+) icon beside the ‘Banners’ heading. From there, choose a name for your banner, fill in your content, select the channel(s) that you wish to broadcast the banners on.',
    Engage_Description_P3: 'You could also spruce it up in some fancy colours and voila! One banner up and ready to go!',
    Engage_Gamification: 'Gamification',
    Engage_Gamification_A_Title: 'Enable Actions and XP',
    Engage_Gamification_A_Description_P1: 'Gamification is a great way to engage the members in your community and it helps to encourage interactions between one another.',
    Engage_Gamification_A_Description_P2: 'Allowing community members to earn XP through actions such as sending messages or reacting to messages is a great way to get started!',
    Engage_Gamification_A_Description_P3: 'First, under Gamification, go to the ‘Actions and XP’ page and click on the toggle button to turn Actions and XP on.',
    Engage_Gamification_A_Description_P4: 'Next, you can customise the amount of XP for each action or just leave it as the default.',
    Engage_Gamification_Actions_Btn: 'Enable Actions and XP',
    Engage_Gamification_B_Title: 'Enable Tiers, Level and XP',
    Engage_Gamification_B_Description_P1: 'Tiers and Levels are attainable ranks by server members who earn the necessary amount of XP through achieving tasks. Allowing your members to gain levels and rise through the different tiers is a useful tool to acknowledge your most active members!',
    Engage_Gamification_B_Description_P2: 'Here’s how to get it done! Go to the ‘Tiers, Level and XP’ page and toggle on ‘Tiers and Levels’. You can also name your tiers, customise the XP amount as well as your display options!',
    Engage_Gamification_Tiers_Btn: 'Enable Tiers',
    Engage_Gamification_C_Title: 'Enable Badges and Create a Badge',
    Engage_Gamification_C_Description_P1: 'Would you like to award some fancy badges to members who accomplish specific tasks? Here’s how to do it!',
    Engage_Gamification_C_Description_P2: 'Go to ‘Badges’ and toggle on the ‘Badges’ option. Next, click on ‘Add a new badge’ and fill in the required details to create a custom badge for your community.',
    Engage_Gamification_Badge_Btn: 'Enable and Create Badge',
    Engage_Emoji: 'Custom Emojis',
    Engage_Emoji_Description_P1: 'Go to ‘Emojis’ and upload either a PNG, JPG or GIF, if it’s a GIF it’ll be under animated Emojis. Do take note that the maximum size for the files are 256KB!',
    Engage_Emoji_Description_P2: 'Next, copy your widget code and paste it in a container on other platforms and you’re good to go!',
    Engage_Emoji_Btn: 'Add Emojis',
    Engage_Gamification_D_Title: 'Enable Tiers and Levels',
    Engage_Gamification_D_Description_P1: 'Create tiers and customize how much XP is required for members to reach each level.',
    Channels_How_Title: 'How to create a channel',
    Channels_Category_Title: 'Categorise your channels',
    Channels_Description: 'Click and drag channels to reorder or categorise them! The top channel will be the landing channel for new users.',
    Channel_Settings: 'Channel Settings',
    Channel_Name: 'Channel Name',
    Channel_Description: 'Channel Description',
    Channel_Learn_Description: 'Create and organize channels for members to communicate with each other.',
    Channel_Input_Placeholder: 'Input text here',
    Channel_Options: 'Channel Options',
    Channel_Options_Desc: 'All users can access public channels. Only selected roles/users can access private channels.',
    Learn_More: 'Learn More',
    Channel_Private_How_Title: 'Creating a private channel',
    Channel_Public: 'Public',
    Channel_Private: 'Private',
    Channel_Role_Permissions: 'Channel Permissions',
    Channel_accent: 'Accent',
    Channel_text: 'Text',
    Channel_background: 'Background',
    Channel_divider: 'Divider',
    Channel_shadow: 'Shadow',
    Add_Chart: 'Add Charts',
    Select_Chart: 'Select Chart',
    Enable_Welcome_Screen: 'Enable Welcome Screen',
    disclaimer_enabled_desc: 'New members will have to agree to the disclaimers before they can interact.',
    disclaimer_disabled_desc: 'Enable when you’re finished.',
    Disable: 'Disable',
    Enable: 'Enable',
    Edit: 'Edit',
    Remove: 'Remove',
    Save: 'Save',
    Disclaimers: 'Disclaimers',
    Add_disclaimer: 'Add a disclaimer',
    Suggested_Rules_Disclaimers: 'Suggested Rules & Disclaimers',
    Be_respectful: 'Be respectful',
    Be_respectful_desc: 'Be respectful to other members in the server. No racism, homophobia and other hate speech.',
    No_NSFW_Button: 'No NSFW or obscene content',
    No_advertising_promotion: 'No advertising or promotion',
    Access_And_Channel_Role_Permissions: 'Access and Channel Permissions',
    Channel_Role_Permissions_Description: 'Customise permissions for roles/users here.',
    Private_Channel_Role_Permissions_Description: 'Allow specific roles/users to view and access this channel by adding them here.',
    Audio_Public_Role_Description: 'Grant Permissions to speak by adding roles/users here and selecting the respective checkboxes.',
    Audio_Private_Role_Description: 'Allow specific roles/users to access this channel by adding them here. Grant permissions to speak and share screen through the checkboxes.',
    Video_Public_Role_Description: 'Grant Permissions to turn on video, speak and share screen by adding roles/users here and selecting the respective checkboxes.',
    Video_Private_Role_Description: 'Allow specific roles/users to access this channel by adding them here. Grant permissions to turn on video, speak and share screen through the checkboxes.',
    Announce_Public_Role_Description: 'Assign Announcers by adding roles/users here and selecting the respective checkboxes.',
    Announce_Private_Role_Description: 'Allow specific roles/users to access this channel by adding them here. Assign Announcers through the checkboxes.',
    Direct_Public_Role_Description: 'Assign Messengers by adding roles/users here and selecting the respective checkboxes.',
    Direct_Private_Role_Description: 'Allow specific roles/users to access this channel by adding them here. Assign Messengers through the checkboxes.',
    Whisper_Public_Role_Description: 'Assign Whisperers by adding roles/users here and selecting the respective checkboxes.',
    Whisper_Private_Role_Description: 'Allow specific roles/users to access this channel by adding them here. Assign Whisperers through the checkboxes.',
    Channel_Role_Permissions_Select: 'Select User or Roles',
    Tooltip_ChannelType_Open: 'A regular channel for members to chat.',
    Tooltip_ChannelType_Announcement: 'A 1-way channel for announcers to post messages. Members can only read and react to messages.',
    Tooltip_ChannelType_DM: 'A direct message channel where members can message a list of pre-selected users.',
    Tooltip_ChannelType_Audio: 'A channel where users are able to have voice conversations.',
    Tooltip_ChannelType_Video: 'A channel where users are able to have video conversations.',
    Tooltip_ChannelType_Whisper: "An anonymous channel where users cannot see each other's messages. Only assigned whisperers can see all messages and have their messages seen by everyone.",
    Whispers: 'Whisperers',
    Messengers: 'Messengers',
    Announcers: 'Announcers',
    No_Roles_Added_Yet: 'NO ROLES ADDED YET',
    No_Users_Added_Yet: 'NO USERS ADDED YET',
    Channel_Search_Placeholder: 'Search roles or @username',
    Channel_Type: 'Type of Channel',
    Open_Channel_Type_Desc: 'What are Text Channels?',
    Audio_Channel_Type_Desc: 'What are Audio Channels?',
    Video_Channel_Type_Desc: 'What are Video Channels?',
    Whisper_Channel_Type_Desc: 'What are Whisper Channels?',
    Announce_Channel_Type_Desc: 'What are Announcement Channels?',
    Direct_Channel_Type_Desc: 'What are Direct Channels?',
    Channel_Open: 'Open',
    Channel_Announcement: 'Announcement',
    Channel_Text: 'Text',
    Channel_Direct_Message: 'Direct Message',
    Channel_Audio: 'Audio',
    Channel_Video: 'Video',
    Channel_DM: 'Direct Message',
    Channel_Whisper: 'Whisper',
    Channel_Enable_Iframe: 'Enable iFrame & Embeds',
    Channel_Embed_Type_Is: 'Embed type is ',
    Enable_iFrame_Chatroom: 'Enable iFrame Chatroom',
    Channel_Enable_Iframe_Description: 'Embed external websites via iFrames or codes from other websites e.g.Youtube, Vimeo, etc.',
    Channel_Enable_Widget_Description: 'Generate a HTML script to embed your channel on external websites. Viewers will be able to see chat activity but are unable to reply.',
    General: 'General',
    Role_Name: 'Role Name',
    Role_Colours: 'Role Colours',
    General_Community_Permissions: 'Community Permissions',
    General_Channel_Permissions: 'Channel Permissions',
    Community_Owner: 'Community Owner',
    Owner: 'Owner',
    Admin: 'Admin',
    Community_Admin: 'Community Admin',
    Role_Learn_Description: 'Create roles to organize members and assign permissions.',
    Roles_How_Title: 'How to create and assign roles',
    Roles_How_Description: 'Members are displayed in your community as the highest role assigned to them. Click and drag to reorder roles.',
    Roles_Permission_1: 'Allow Direct Messages(DM)',
    Roles_Permission_1_Desc: 'Users are able to DM other members in the community',
    Roles_Permission_2: 'Community Invite',
    Roles_Permission_2_Desc: 'Users will have a personal invite link they can use to invite others into the community',
    Roles_Permission_3: 'Kick Members',
    Roles_Permission_3_Desc: 'Users can remove other members from this server. Kicked members can rejoin if they receive another invite',
    Roles_Permission_4: 'Broadcast Messages',
    Roles_Permission_4_Desc: 'Users can broadcast their messages across multiple communities and channels that they are a part of',
    Roles_Permission_5: 'Ban Members',
    Roles_Permission_5_Desc: 'Users can ban other members',
    Roles_Permission_6: 'Admin',
    Roles_Permission_6_Desc: 'Users will have permissions similar to the community owner and are able to manage the community and all channels (Take caution when assigning this to a role)',
    Roles_Permission_7: 'View Message History',
    Roles_Permission_7_Desc: 'Allow users to read previous messages sent in the channel prior to joining.',
    Roles_Permission_8: 'Delete Messages',
    Roles_Permission_8_Desc: 'Users can delete messages by other users',
    Roles_Permission_9: 'Allow Mentions',
    Roles_Permission_9_Desc: 'Other users can @mention this role in the chat ',
    Roles_Permission_10: 'Post Messages',
    Roles_Permission_10_Desc: 'Users can post messages in the chat.',
    Roles_Permission_11: 'Attach Files',
    Roles_Permission_11_Desc: 'Users can attach files in the chat.',
    Roles_Permission_12: 'Warn Members',
    Roles_Permission_12_Desc: 'Users can warn other members',
    Roles_Permission_13: 'Suspend Members',
    Roles_Permission_13_Desc: 'Users can suspend other members',
    Roles_Permission_14_Heading: 'To enable language overwriting, please turn on',
    Roles_Permission_14: 'Overwrite Language Settings',
    Roles_Permission_14_Desc: 'Users can overwrite language settings of other users',
    Welcome: 'Welcome',
    Welcome_Screen: 'Welcome Screen',
    Welcome_Description: 'The welcome screen is displayed to the user when they enter your community. It’s a way to help new members discover what your community is about.',
    Welcome_To: 'Welcome to',
    Welcome_Community: 'The official community of',
    Welcome_Allow: 'I allow the following details to be shared with Community Owners & Administrators',
    Join_Community: 'Join Community',
    Joining_Requirement: 'Joining Requirement',
    Joining_Req_Description: 'To join the community, new members must agree to share the following details with Community Owner and Admins.',
    Rules_Disclaimers: 'Rules and Disclaimers',
    Rules_Disc_Desc: 'Set up Rules and Disclaimers that new members have to agree to before they can access your community.',
    Rules_Agree: 'Create rules & disclaimers that new members have to agree to before they can access your community.',
    Rules_Setup_Btn: 'Set Up Rules & Disclaimers',
    Integration: 'Integration',
    Integration_Incoming: 'Incoming Webhooks',
    Integration_Incoming_How: 'How to create incoming webhook',
    Integration_Incoming_Desc: 'Receive and integrate data from external apps into your community.',
    Integration_Outgoing: 'Outgoing Webhooks',
    Integration_Outgoing_How: 'How to create an outgoing webhook',
    Integration_Outgoing_Desc: 'How to create an outgoing webhook',
    Integration_PM: 'New Public Message Posted Anywhere',
    Integration_PM_desc: 'Triggers when a new message is posted to any public channel',
    Integration_CM: 'New Message Posted to Channel',
    Integration_CM_desc: 'Triggers when a new message is posted to a specific #channel you choose.',
    Integration_NM: 'New Mention',
    Integration_NM_desc: 'Triggers when a specific user is @mentioned in a selected channel.',
    Integration_NMS: 'New Message (specific users)',
    Integration_NMS_desc: 'Triggers when a specific user sends a message in a selected channel.',
    Integration_R: 'Reactions',
    Integration_R_desc: 'Triggers when any user reacts to a message.',
    Integration_RU: 'Reactions (specific users)',
    Integration_RU_desc: 'Triggers when specific user reacts to a message.',
    Integration_TW: 'Trigger Word',
    Integration_TW_desc: 'If this word is detected in any of the messages, it will trigger this event to send the entire message to the receiving webhook.',
    Integration_CA: 'User creates analysis',
    Integration_CA_desc: 'Triggers when a specific user creates an analysis in a certain channel.',
    Integration_UA: 'User updates analysis',
    Integration_UA_desc: 'Triggers when a specific user updates an analysis in a certain channel.',
    Integration_UVS: 'User Visits Server',
    Integration_UVS_desc: 'Triggers when a user visits the server',
    Integration_UJS: 'User Joins Server',
    Integration_UJS_desc: 'Triggers when a user joins the server',
    Integration_ULS: 'User Leaves Server',
    Integration_ULS_desc: 'Triggers when a user leaves the server',
    Integration_RJC: 'New request to join a particular channel via request management',
    Integration_RJC_desc: 'Triggers when a new request is sent to a selected channel.',
    Integration_AB: 'Any user is awarded a badge',
    Integration_AB_desc: 'Triggers when any user achieves a badge or is awarded a badge.',
    Integration_NLA: 'New level achieved by users',
    Integration_NLA_desc: 'Triggers when any user achieves a new level in your server.',
    Integration_MI: 'Misconduct is issued',
    Integration_MI_desc: 'Triggers when any user receives a warning, suspension or is banned.',
    Webhook_Settings: 'Webhook Settings',
    Webhook_Name: 'Webhook Name',
    Recatch: 'Recatch',
    Catch: 'Catch',
    Copy_URL: 'Copy URL',
    Webhook_New: 'New Webhook',
    Avatar: 'Avatar',
    Type_Message: 'Type your message here',
    Webook_Message_Template: 'Message Template',
    Webhook_Post_To: 'Post to',
    Webhook_Post_Desc: 'Enabling this would allow webhooks to post a message on the following channel(s)',
    Delete_Webhook: 'Delete Webhook',
    Select_Channels: 'Select Channels',
    Select_Deselect: 'Select/Deselect all',
    Recent_Deliveries: 'Recent Deliveries',
    Your_message_here: 'Your message here.',
    Request_URL: 'Request URL',
    Secret: 'Secret',
    Secret_token: 'Secret token',
    Request_Format: 'Request Format',
    Trigger_Events: 'Trigger Events ',
    Select_Events: 'Select Events',
    To_Users: 'To Users',
    Request: 'Request',
    Response: 'Response',
    Headers: 'Headers',
    Body: 'Body',
    Payload: 'Payload',
    Triggering_Words: 'Triggering Words',
    Select_Channels_desc: 'Select the channels you wish to monitor for new messages',
    Banner_How: 'How to create a banner',
    Banner_Desc: 'Create targeted pop-ups displayed in specific channels of your choice.',
    Create_New_Banner: 'Create New Banner',
    Edit_Banner: 'Edit Banner',
    Banner_Title: 'Banner Title',
    Required_Field: 'Required Field',
    New_Banner: 'New Banner',
    Banner_Message: 'Banner Message',
    Call_To_Action: 'Call To Action (Optional)',
    Invalid_URL: 'Invalid URL',
    Label: 'Label',
    Sender: 'Sender',
    Do_not_Show: 'Do not Show',
    Select_Sender: 'Select Sender',
    Admins: 'Admins',
    At_least_1_channel: 'You should select at least 1 channel',
    Select_Banner_Channel_desc: 'Select Channels where you want your banner to be displayed.',
    At_least_1_role: 'You should select at least 1 role',
    Select_Banner_role_desc: 'Select roles that you want your banner to be displayed to.',
    Select_Roles: 'Select Roles',
    Start_sending: 'Start sending',
    Stop_sending: 'Stop sending',
    Immediately: 'Immediately',
    Set_Live: 'Set Live',
    Schedule: 'Schedule',
    Save_and_Close: 'Save and Close',
    Pause: 'Pause',
    Revert_to_Draft: 'Revert to Draft',
    Preview: 'Preview',
    Style: 'Style',
    Position: 'Position',
    Inline: 'Inline',
    Floating: 'Floating',
    Top: 'Top',
    Bottom: 'Bottom',
    Live: 'Live',
    Background_Colour: 'Background Colour',
    Message_Text_Colour: 'Message Text Colour',
    Call_To_Action_Colour: 'Call To Action Colour',
    No_banners_created: 'No banners created',
    Profanity_How: 'How to set up profanity filter',
    Profanity_Desc: 'Set up content moderation to ensure a safe and respectful space for your community.',
    Profanity_Filter: 'Profanity Filter',
    Profanity_Filter_Desc: 'Turn this on to prevent users from sending profanities. You can remove or add words to the default set of profanities.',
    Profanity_Banned_Desc: 'Prevent messages with banned words from being sent. A warning will be shown to users attempting to send them.',
    Profanity_Banned_How: 'How to set up banned words',
    Profanity_Banned_Exact_Match: 'Exact Match',
    Profanity_Banned_Contains: 'Contains',
    Profanity_Banned_List: 'Paste list or type word here, separate each word with a comma',
    Profanity_Banned_Warn: 'Warning message for Banned Words',
    Profanity_Banned_Warn_Placeholder: 'Enter a warning message for users who use any of the words above',
    Profanity_Alert_Words: 'Alert Words',
    Profanity_Alert_Desc: 'Keep track of certain words in your community and send an alert to a specific channel of your choice.',
    Profanity_Alert_How: 'How to set up alert words',
    Profanity_Alert_Log: 'Channel Selection for Alert Log',
    Profanity_Alert_Log_Desc: 'Select a channel for alert logs to be sent to. Only community owner and admins can see the logs in this channel.',
    Edit_Default_List: 'Edit Default List',
    Warning_Message_for_Profanities: 'Warning Message for Profanities',
    Profanity_List: 'Profanity List',
    Hide_List: 'Hide List',
    Show_List: 'Show List',
    Exclude_Profanities_from_List: 'Exclude Profanities from List',
    Add_Profanities_to_List: 'Add Profanities to List',
    Paste_list_placeholder: 'Paste list or type words/phrases here, separated with commas',
    Paste_list_placeholder1: 'Paste list or type words/phrases here, separated with commas',
    Appearance_Custom: 'Customise the look and feel of your community to reflect its identity.',
    Icon: 'Icon',
    Logo_Dark_Theme: 'Logo (Dark Theme)',
    Logo_Light_Theme: 'Logo (Light Theme)',
    Edit_Image: 'Edit Image',
    Logo: 'Logo',
    Click_to_delete: 'Click to delete',
    Click_to_upload: 'Click to upload',
    dark_theme_logo: 'dark theme logo',
    light_theme_logo: 'light theme logo',
    Customize_Header_Bar: 'Customize Header Bar',
    Appearance_BG_Image: 'Login/Register Background Image',
    Appearance_BG_Desc: 'This image appears when users login or register to join your community.',
    Click_to_edit_image: 'Click to edit image',
    Click_to_upload_image: 'Click to upload image',
    URL: 'URL',
    Customise_URL: "Customise your community's URL",
    Customise_Theme: 'Customise Theme',
    Customise_Colours: 'Customise Colours',
    Default_Theme: 'Default Theme',
    Server_Bot: 'Server Bot',
    Server_Bot_desc: 'Notification bot in this server will use the below bot image and name',
    Bot_Image: 'Bot Image',
    Minimum_image_size_48_48: 'Minimum image size is 48 x 48 px',
    Bot_Name: 'Bot Name',
    Modal_Channel_Type_Convert: 'Channel Type Convert',
    Modal_Channel_Type_Convert_Description: 'When you convert a “Whisper” Channel to an “Text” or “Announcement” Channel, all past whispers sent by users will be made visible to everyone.',
    Cancel: 'Cancel',
    Proceed: 'Proceed ',
    Done: 'Done',
    None_Participant: 'No participants yet',
    One_Participant: 'participant',
    Multi_Participant: 'participants',
    Chart_desc: 'Enable members in your community to use trading charts or browse and edit others’ charts.',
    Chart_Permissions: 'Chart Permissions',
    Chart_Permissions_desc: 'Customise what members/roles can do with charts.',
    Select_chart_desc: 'Select charts that members in your community can access.',
    Font: 'Font',
    Language_token_limit: 'Monthly Token Limit Reached',
    Language_token_limit_desc: 'You have reached the monthly token limit. This feature will be disabled until your token count refreshes next month.',
    Language_token_limit_desc1: 'You can purchase Translation Token Packs to increase your token limit.',
    Purchase: 'Purchase',
    Ban_desc: 'Take action against misconduct by warning, suspending or banning users.',
    Filter_by: 'Filter by',
    No_Misconduct: 'No Misconduct',
    All_Misconduct: 'All Misconduct',
    Warned: 'Warned',
    Suspended: 'Suspended',
    Banned: 'Banned',
    Warn: 'Warn',
    Suspend: 'Suspend',
    Ban: 'Ban',
    Revoke: 'Revoke',
    Revoke_Ban: 'Revoke Ban',
    Revoke_Suspension: 'Revoke Suspension',
    Assign_Misconduct: 'Assign Misconduct',
    Effective_Till: 'Effective Till',
    Misconduct_History: 'Misconduct History',
    Select: 'Select',
    Suspend_for: 'Suspend for',
    Days_label: 'Days',
    Weeks_label: 'Weeks',
    Months_label: 'Months',
    warned_title: 'Warn',
    suspended_title: 'Suspend',
    banned_title: 'Ban',
    revoke_title: 'Revoke',
    Ban_warned_confirm: 'Are you sure you want to warn ',
    Ban_suspended_confirm: 'Are you sure you want to suspend ',
    Ban_banned_confirm: 'Are you sure you want to ban ',
    Ban_revoke_confirm: 'Are you sure you want to revoke ',
    Reason_warned: 'Reason for Warning',
    Reason_suspended: 'Reason for Suspension',
    Reason_banned: 'Reason for Ban',
    Reason_revoke: 'Reason for Revoke',
    warned_desc: 'This user will  receive a notification and email informing them about the violation.',
    suspended_desc: 'This user will be temporarily suspended from accessing the community, and will receive a notification and email informing them about the violation.',
    banned_desc: 'This user will receive a notification and email informing them about the violation.',
    revoke_suspended_desc: 'Reason for suspension',
    revoke_banned_desc: 'Reason for ban',
    Provide_reason: 'Provide a reason',
    Enter_Reason: 'Enter Reason',
    ban_item_desc1: 'Delete all messages from user',
    ban_item_desc2: 'Display user as "Deleted User", keep original messages',
    ban_item_desc3: 'Display user as "Deleted User", show messages as "Deleted Message"',
    Performance_Display: 'Performance Display',
    Performance_Display_desc: 'Specify the leaderboard details to display on the leaderboard.',
    Only_show_top_results: 'Only show top results',
    Top_results_desc: 'The rest of the participants on the leaderboard will be blurred out.',
    Fill_dummy_information: 'Fill leaderboard with dummy information if there is insufficient data',
    Fill_dummy_desc: 'This option will be disabled when ‘Only show top results’ toggle is turned off.',
    Only_show_positive: 'Only show participants with positive value',
    Leaderboard_Duration: 'Leaderboard Duration',
    Leaderboard_Duration_desc: 'Specify the duration for the leaderboard to be accessible.',
    No_End_Date: 'No end date',
    Start_Date: 'Start Date',
    End_Date: 'End Date',
    textField: {
        typeHere: 'Type here...',
        noPermission: 'You do not have permission to send messages in this channel.',
        noPermissionMobile: 'You do not have permission to send messages.',
        join: 'Join ',
        serverDiscussion: ' server to take part in this discussion',
    },
    channels: {
        getStarted: 'Get Started',
        noChannels: 'NO CHANNELS AVAILABLE',
        noChannelsDesc: 'Create a channel for your community to have discussions in.',
    },
    tooltips: {
        Direct_Message: 'Direct Message',
        Notifications: 'Notifications',
        Profile: 'Profile',
        Personal_Chart: 'Personal Chart',
        User_Settings: 'User Settings',
        Please_wait: 'Please wait',
        Language: 'Language',
        Home: 'Home',
    },
    notifications: {
        My_Activity: 'My Activity',
        Channel_Activity: 'Channel Activity',
        turn_on: 'Turn on these notifications',
        turn_off: 'Turn off these notifications',
        delete: 'Delete this notification',
        Mark_as_read: 'Mark as read',
        Mark_as_unread: 'Mark as unread',
        getting_ready: 'Getting your notifications ready...',
        stayed_tuned: 'Stay tuned and we will notify you once you have any notifications!',
        no_noty: 'No notifications yet',
        mark_all: 'Mark all as read',
    },
    days: {
        TODAY: 'Today',
        YESTERDAY: 'Yesterday',
        SUNDAY: 'Sunday',
        MONDAY: 'Monday',
        TUESDAY: 'Tuesday',
        WEDNESDAY: 'Wednesday',
        THURSDAY: 'Thursday',
        FRIDAY: 'Friday',
        SATURDAY: 'Saturday',
    },
    months: {
        January: 'January',
        February: 'February',
        March: 'March',
        April: 'April',
        May: 'May',
        June: 'June',
        July: 'July',
        August: 'August',
        September: 'September',
        October: 'October',
        November: 'November',
        December: 'December',
    },
    Language_Settings_Page: {
        Language_Settings: 'Language Settings',
        Select_Default: 'Select the default language for your community and supported language translations',
        Community_Language: 'Community Language',
        Default_Language: 'Default language that your community will be displayed in.',
        AI_Message_Toggle: 'AI Message Translations',
        AI_Message_Description: 'Enable community members to translate messages into their preferred language using GPT-3.5-turbo. Alternatively, you can use your preferred translation engine by entering your OpenAI API key.',
        Token_Usage: 'Token Usage',
        Supported_Languages: 'Supported Languages',
        Supported_Languages_Description: 'Select supported languages for translation. Take note that you have to pay for each additional language you select.',
        Select_Languages: 'Community Context',
        Community_Content: 'Community Context',
        Community_Content_Description: 'Genesiv uses Chat GPT to automatically translate content. Please provide contextual information about your community to help make these translations more accurate.',
        Context_Example: 'E.g. games and entertainment',
    },
    Login_Page: {
        Welcome_Back: 'Welcome Back!',
        Welcome_to: 'Welcome to',
        Sign_In: 'Sign in to your account',
        Sign_In_With_Google: 'Sign in with Google',
        Sign_In_With_Facebook: 'Sign in with Facebook',
        Sign_In_With_Apple: 'Sign in with Apple',
        Or_use_email: 'or use your email account',
        Username_Email: 'Username/Email',
        Password: 'Password',
        Remember_me: 'Remember me',
        Forgot_password: 'Forgot Password?',
        Login: 'Login',
        Otp: 'Enter OTP',
        Send_Otp: 'Send OTP',
        Sending_Otp: 'Sending OTP',
        Login_Via_Password: 'Login via Password',
        Login_Via_Otp: 'Login via OTP',
        Otp_Success: 'OTP has been sent to your registered email address',
        Verify_Otp: 'Verify OTP Code',
        Verifying_Otp: 'Verifying OTP Code',
        Did_not_receive_otp: "Didn't receive an OTP?",
        Have_Received_Otp: 'Have you received a verification code?',
        Enter_OTP_code: 'Enter OTP code sent to your email',
        Resend_code: 'Resend Code',
        Login_in: 'Logging you in...',
        Terms_of_Service: 'Terms of Service',
        Privacy_Policy: 'Privacy Policy',
        Not_member: 'Not a member?',
        Register_Now: 'Register Now',
        Username_Email_Required: 'Username/Email is required',
        Password_Required: 'Password is required',
        Username_Or_Email_Incorrect: 'The username/email or password you have entered is incorrect. Please try again',
        Username_Does_Not_Exist: 'Sorry! The email/username does not exist',
        Username_Emai_Or_Password_Incorrect: 'The username/email or password you have entered is incorrect. Please try again',
        Forgot_your_password: 'Forgot your password?',
        Enter_username_or_email: 'Enter your username or email address to receive a security code at your registered email address.',
        Username_or_Email: 'Username or Email address',
        Reset_Password: 'Reset Password',
        Invalid_username: 'Invalid username',
        Password_reset_successful: 'Password Reset Successful',
        Back_to_Login: 'Back to Login',
        Invited_to: "You've been invited to",
        online: 'online',
        member: 'member',
        members: 'members',
        Allow_details_shared: 'I allow the following details to be shared with Server Owners & Administrators.',
        Join: 'Join',
        Need_to_agree_rules_disclaimers: 'You’ll need to agree to the rules & disclaimers before you can interact with other members in the community.',
        Community_Rules_Disclaimers: 'Community Rules and Disclaimers',
        Agree_to_Community_Rules_Disclaimers: 'I agree to the Community Rules and Disclaimers',
    },
    Register_Page: {
        Create_Account: 'Create Account',
        First_Name: 'First Name',
        Last_Name: 'Last Name',
        E_mail: 'E-mail (Cannot be changed)',
        Username: 'Username (Cannot be changed)',
        Password: 'Password',
        Register: 'REGISTER',
        Creating_Your_Account: 'CREATING YOUR ACCOUNT...',
        Confirm_Password: 'Confirm Password',
        Terms_of_Service: 'Terms of Service',
        Privacy_Policy: 'Privacy Policy',
        Already_Existing_Member: 'Already an existing member?',
        Login: 'Login',
        Confirm_Your_Password: 'Please confirm your password',
        Type_your_password_again: 'Type your password again to confirm',
        Password_do_not_match: 'Passwords do not match',
        Password_required: 'Password is required',
        Check_your_email_address: 'Please check your email address',
        First_name_or_Last_name_required: 'First name or Last name is required',
        Name_requirments: 'Name length should be greater than 1 and can not contain periods(.), exclamation points(!), accent grave(`), and brackets([])',
    },
    Password_Rules: {
        Password_must_include: 'Password must include:',
        characters: 'characters',
        at_least_one_lower_character: 'Have at least one lower case character',
        at_least_one_capital_character: 'Have at least one capital character',
        at_least_one_nubmer: 'Have at least one number',
        at_least_one_special_character: 'Have at least one special character e.g. $^*%#!',
        not_same_as_username: 'Not be the same as Username',
        no_spaces: 'No spaces',
    },
    Invite_Links: {
        list_title: 'Invite Links',
        description: 'Manage and track your custom invite links here. Do note that you have to set up your custom domain before using this feature.',
        disallow_modal_title: 'Custom Domain Required',
        disallow_modal_content: 'A custom domain is required before you can set up custom invite links.',
        disallow_modal_button_text: 'Okay',
        invitation_name: 'Invitation Name',
        invitation_name_placeholder: 'Enter invitation name',
        invitation_name_description: 'For internal use, only you will be able to see this.',
        custom_invite_link: 'Custom Invite Link',
        custom_invite_link_description: 'Edit your link by typing in the input field.',
        assign_role: 'Assign Role',
        joining_limit: 'Joining Limit',
        joining_limit_description: 'Max number of users who can join community via this link',
        joining_limit_no_limit: 'No Limit',
        joining_limit_set_max_number: 'Set Max Number',
        link_expiry: 'Link Expiry',
        link_expiry_description: 'Link will be invalid past the expiry date',
        link_expiry_never: 'Never',
        link_expiry_custom_date: 'Custom Date',
        tooltip_create: 'Create New',
        header_create: 'Create New Custom Invitation',
        header_edit: 'Edit Custom Invitation',
        button_cancel: 'Cancel',
        button_create: 'Create',
        button_save: 'Save',
        button_done: 'Done',
        table_header_name: 'Name',
        table_header_link: 'Custom Link',
        table_header_clicks: 'Clicks',
        table_header_joined: 'Joined',
        table_header_expiry: 'Expiry',
        table_header_role: 'Role',
        table_header_edit: 'Edit',
        table_header_delete: 'Delete',
        users_modal_header: 'Joined Members For',
        users_modal_search: 'Search',
        users_modal_header_member: 'Members',
        users_modal_header_signup: 'Sign-Up Date',
    },
    Advanced_Referral_Tracking: {
        list_title: 'Advanced Referral Tracking',
        desc: "Configure the community's referral programs.",
        main_enable_label: 'Enable Advanced Referral Tracking',
        main_enable_desc: 'Implement the qualifying criteria for the referral program within the community.',
        message_tracking_label: 'Send a specified minimum number of messages',
        message_tracking_desc: 'No. of messages',
        reply_tracking_label: 'Replies to a specified minimum number of messages',
        reply_tracking_desc: 'No. of replies',
        reaction_tracking_label: 'React a certain number of times',
        reaction_tracking_desc: 'No. of reactions',
        hour_tracking_label: 'Be logged in for an accumulated number of hours',
        hour_tracking_desc: 'No. of hours',
        day_tracking_label: 'Log in at least once over an accumulated number of specified days.',
        day_tracking_desc: 'No. of days',
        profile_tracking_label: 'Add a profile picture',
        archive_tracking_label: 'Achieve a designated @role',
        archive_tracking_placeholder: 'Select Roles',
        archive_tracking_box_label: 'Add Roles',
        archive_tracking_box_search_placeholder: 'Search roles',
        archive_tracking_box_select_all_label: 'Select / Deselect all in this column',
        archive_tracking_box_role_label: 'Roles',
    },
    Custom_Desktop_App: 'Custom Desktop App',
    Custom_App_Page: {
        'Header-Description': 'Obtain your own branded Desktop app that you can freely design and distribute to your community.',
        Compatibility: 'Compatibility',
        Customisation: 'Customisation',
        You_can: 'You can customise',
        App_name: 'App name',
        App_Icon: 'App Icon',
        Launch_Screen: 'Launch Screen',
        Desktop_App_Icon: 'Desktop App Icon',
        App_Dimension: 'App Dimension',
        Enter_App_name: 'Enter your App name',
        Button_Create: 'Create Desktop App',
        Button_Progress: 'In Progress...',
        Request_Sent: 'request sent!',
        Button_Regenerate: 'Regenerate',
        Download_links: 'Download links',
        Last_Updated: 'Last Updated',
        Download_link_available: 'The download link will be available here within 1 business day.',
        Custom_Domain_Required: 'Custom Domain Required',
        Custom_Domain_Required_Before: 'A custom domain is required before you can set up Custom Desktop App.',
        Done: 'Done',
        Pixels: 'pixels',
        Frequently_Asked: 'Frequently Asked Questions',
        Frequent_Question_1: 'How long does it take to set up the app?',
        Frequent_Question_Ans_1: 'Upon receiving your request, the team will get your app out within 1 business day.',
        Frequent_Question_2: 'How will the app be updated?',
        Frequent_Question_Ans_2: 'The app will automatically update on users’ devices.',
        Frequent_Question_3: 'How often can I update the app?',
        Frequent_Question_Ans_3: 'You can update the app once a week.',
        Frequent_Question_4: 'What happens when I cancel my Desktop App Add-On subscription?',
        Frequent_Question_Ans_4: 'The app will still be available for you and your community until the end of the month, when the billing cycle ends. After which, the app will be invalid.',
    },
    Domain_And_Email: {
        Title_Domain: 'Domain',
        Sub_Header_Domain: 'Create a branded experience by hosting your community on a custom domain. Members will access it through a branded URL such as community.yourdomain.com.',
        Title_Custom_Domain: 'Custom Domain',
        Sub_Header_Custom_Domain: 'Provide a valid subdomain to link with this server',
        Domain_Not_Authenticated: 'Domain is not authenticated.',
        Click_To_Verify: 'Click Here to verify',
        Domain_Authenticated: 'Domain is authenticated',
        Instructions_Authentication: 'Instructions to authenticate domain',
        Instructions_Authentication_Desc: 'Authenticate this domain by logging in to your domain provider (e.g. GoDaddy) and create the A record in your DNS settings, by copying and pasting the values below.',
        Field_Type: 'Type',
        Field_Host: 'Host/Name',
        Field_Target: 'Target/Value',
        Title_Custom_Email: 'Custom Email Configuration',
        Title_Providers: 'Providers',
        Sub_Header_Email: 'Set up custom email to ensure community emails come from your branded email address. Email notifications will not be sent unless you set this up.',
        Field_SMTP_Host: 'SMTP Host',
        Field_SMTP_Port: 'SMTP Port',
        Field_From_Address: 'From Address',
        Field_From_Address_Desc: 'Enter your sender name, followed by address in angle brackets <>',
        Field_SMTP_Username: 'SMTP Username',
        Field_SMTP_Password: 'SMTP Password',
        Field_Custom_Header: 'Custom Header',
        Field_Enable_Digest: 'Enable Daily Digest Emails',
        Field_Enable_Digest_Desc: 'This is a daily email sent to your community members, containing unread activity from the past 24 hours.',
        Server_Host_Address: 'Server Host Address',
        Domain_Port_Number: 'Domain Port Number',
        From_email: 'From email',
        Add_header_key: 'Add header key',
        Add_header_value: 'Add header value',
        Attempting_connection: 'Attempting connection...',
        Test_Connection_Save: 'Test Connection & Save',
        Custom_Domain_Required: 'Custom Domain Required',
        Custom_Domain_Required_desc: 'A custom domain is required before you can set up custom email configuration.',
        Email_Header_Image: 'Email Header Image',
    },
    Custom_Email: {
        Title: 'Custom Email',
        Sub_Header: 'Send out emails to your members in your community',
        From: 'From',
        Subject: 'Subject',
        Subject_Placeholder: 'Enter subject',
        Pre_Header: 'Pre-header Text',
        Pre_Header_Placeholder: 'Enter pre-header text',
        Email_Message: 'Email Message (HTML)',
        Email_Message_Placeholder: 'Type your message here.',
        Recipients: 'To Roles/Users',
        Recipients_Description: 'Select roles/users that you want to send your email to.',
        Send_Email: 'Send Email',
        Preview: 'Preview',
        Preview_Description: 'This is how your email will look like.',
        Email_Required_Modal: 'Custom Email Configuration Required',
        Email_Required_Modal_Desc: 'Please set up custom email configuration to use this feature. This can be found it Community Settings > Domain and Email.',
        Send_Email_Desc: 'Are you sure you want to send this email?',
    },
    Emoji_Settings: {
        Emoji_Desc: 'Add up to 50 custom emojis for members to use in your community. You can upload static (png, jpg) or animated emojis (gif).',
        Slot_Available: 'SLOTS AVAILABLE',
        Uploaded_By: 'Uploaded By',
        None: 'None',
        Alias: 'Alias',
        Upload_Emoji: 'Upload Emoji',
        ANIMATED_EMOJI: 'ANIMATED EMOJI',
    },
    User_Settings: {
        User_List: 'User List',
        manage_role_desc: 'Manage roles or remove users from your community here.',
        download_list: 'Download user list as CSV',
        edit_fields: 'Edit Fields',
        displaying_fields: 'Displaying Fields',
        delete: 'Delete',
        Display_Name: 'Display Name',
        First_Name: 'First Name',
        Last_Name: 'Last Name',
        Join_Date: 'Join Date',
        Last_Online: 'Last Online',
        Email: 'Email',
        Role: 'Role',
        Adjust: 'Adjust',
        Adjust_XP: 'Adjust XP',
        Bool_update_Field: 'Bool update Field',
        Bool_Field: 'Bool Field',
        Num_Field: 'Num Field',
        Text_Field: 'Text Field',
        Award_badge: 'Award badge',
        Badge_Title: 'Badge Title',
        Description: 'Description',
        Award: 'Award',
        Badge_awarded: 'Badge awarded!',
        Profit_and_Loss: 'Profit and Loss',
        Messages_Sent: 'Messages Sent',
        Replies_Sent: 'Messages Sent',
        Analysis_Created: 'Analysis Created',
        Analysis_Comments: 'Analysis Comments',
        Referrals: 'Referrals',
    },
    Request_Management_Settings: {
        Desc: 'When accepting a user’s request to a private channel, you will need to assign them a role with access to the channel.',
        Pending_Request: 'Pending Requests',
        Request_History: 'Request History',
        No_Request: 'No requests in this channel',
        No_History: 'No request history in this channel',
    },
    Login_API_Settings: {
        Desc: 'Automatically assign roles to users through an API.',
        Api_key: 'API Key',
        Login_with_OTP: 'Login with OTP',
        Value: 'Value',
        Role: 'Role',
        Third_Party_API_Keys: 'Third Party API Keys',
        Generate_Token: 'Generate Token',
        API_Token: 'API Token',
        API_Token_desc: 'This api key and password is displayed here at one time.',
        API_Token_desc1: 'Please save it carefully. If you forgot it, please regenerate new!',
        Password: 'Password',
        Ok: 'Ok',
        Enter_value_limit: 'Enter value up to 5 digits',
        Add_login_api: 'Add Login API',
        Update_login_api: 'Update Login API',
        Enable_OTP_Password: 'Enable OTP / Password for login page',
        OTP_Login: 'OTP Login',
        Password_Login: 'Password Login',
    },
    Third_Party_API_Settings: 'Integration with external services to access current system.',
    Gamification_Settings: {
        Enable_Gamification_System: 'Enable Gamification System',
        Enable_Reward_System: 'Enable In-game Reward System',
        Desc: 'Reward members with XP (experience points) for completed tasks to boost community engagement.',
        Actions_XP_Title: 'Actions and XP',
        Actions_XP_Desc: 'This allows users to earn XP when they complete an action.',
        Reward_Desc: 'Allow users to earn currency and purchase things on the platform',
        Experience_Points_Unit: 'Experience Points Unit',
        Experience_Points_Icon: 'Experience Points Icon',
        Currency_Unit: 'Currency Unit',
        Currency_Icon: 'Currency Icon',
        In_Game_Currency: 'In-game Currency',
        Convert_XP_Currency_Title: 'Convert XP and in-game currency',
        Convert_XP_Currency_Desc: 'Convert the XPs into in-game currency and vice versa.',
        Configuration_Display_Title: 'Configuration Display',
        Configuration_Display_Desc: 'Customise the display format for the metrics.',
        Experience_Points: 'Experience Points (XP)',
        Reward_Currency: 'Reward Currency',
        Gamification_Unit_Title: 'Gamification Unit',
        Gamification_Unit_Desc: 'Customise the name of the gamification unit that members can earn. By default, the gamification unit is XP.',
        Actions_Title: 'Actions',
        Actions_Desc: 'Select actions for members to earn XP and customise how much XP can be earned from each action.',
        Tiers_And_Levels_Title: 'Tiers and Levels',
        Tiers_And_Levels_Desc: 'Create tiers and customize how much XP is required for members to reach each level.',
        Display_Levels_Title: 'Display levels in channels',
        Display_Levels_Desc: "Displays user's level under their username in channels",
        Display_Tiers_Title: 'Display tiers in channels',
        Display_Tiers_Desc: "Displays user's tier beside their username in channels",
        Actions_Behaviour: 'Actions / behaviours',
        Cooldown_secs: 'Cooldown (secs)',
        Add_Tiers: 'Add Tiers',
        Image_Col: 'Image',
        Tier_Col: 'Tiers',
        Level_Col: 'Levels',
        Messages: 'Messages',
        Replies: 'Replies',
        Reactions: 'Reactions',
        Create_Analysis: 'Create Analysis',
        Analysis_Comment: 'Analysis Comment',
        Linked_Text: 'Linked Text',
        Trading_Discussions: 'Trading Discussions',
        Daily_Visit: 'Daily Visit',
        Refer_Friends: 'Refer Friends',
        Badges: 'Badges',
        Badge_desc: 'How to set up badges',
        Badge_desc1: 'Add new badges and set the actions required to attain them',
        Badge_Title: 'Badge Title',
        Action: 'Action',
        Add_a_new_badge: 'Add a new badge',
        Display_badge: 'Display badges in channels',
        Display_badge1: 'Displays the user’s badges beside their username in channels',
        Members: 'Members',
        No_Badges: 'No Badges',
        Pending_Requests: 'Pending Requests',
        Approved: 'Approved',
        Rejected: 'Rejected',
        No_Badge_Request: 'No badge requests in this server',
        Accept: 'Accept',
        Reject: 'Reject',
        No_message_from_user: 'No message from user',
        No_approval_history: 'No approval history in this server',
        Request_Approved: 'Request Approved',
        Request_Rejected: 'Request Rejected',
        Add_badge: 'Add badge',
        Edit_badge: 'Edit badge',
        Choose_an_action: 'Choose an action',
        Quantity: 'Quantity',
        Attachment_Desc: 'Make uploading attachments a required field. Users will only be able  to send requests after uploading an attachment.',
        Upload_Badge_Image: 'Upload Badge Image',
        Changes_cannot_be_saved: 'Changes cannot be saved. Fields are empty or XP is lower than lower levels.',
        View_Attached_Files: 'View Attached Files',
        Attachments: 'Attachments',
        Download_all_attachments: 'Download all attachments',
        Reject_Badge_Request: 'Reject Badge Request',
        Reject_Request_desc: 'Are you sure you want to reject the badge request?',
        Reject_Request_desc1: 'The user will receive a notification and email informing them about the rejection.',
        Preview_Mode: 'Preview Mode',
        Manage_Badges: 'Manage Badges',
        Custom: 'Custom',
        Total: 'Total',
        Messages_Sent: 'Messages Sent',
        Replies_Sent: 'Replies Sent',
        Reactions_Sent: 'Reactions Sent',
        analysis_created: 'analysis created',
        linked_text: 'linked text',
        comments_on_an_analysis_discussion: 'comments on an analysis discussion',
        comments_on_a_linked_text_discussion: 'comments on a linked text discussion',
        signals_that_hit_profit: 'signals that hit profit',
        Refer: 'Refer',
        Friends: 'Friends',
        Daily_Visit_Streak: 'Daily Visit Streak',
        XP_Multiplier: 'XP Multiplier',
        XP_Multiplier_Desc: 'Multiply XP earned for messages, replies and reactions in selected channels.',
        Channels: 'Channels',
        No_Channels: 'No channels selected',
        Multiplier_Modal_Title: 'Select Channels for XP Multiplier',
        Role_Assignment: 'Role Assignment',
        Role_Assignment_Desc: 'Select roles to automatically assign them to users when they earn a certain amount of XP.',
        Private_Channel_Access: 'Private Channel Access',
        No_Roles: 'No roles created',
    },
    Analytic_Overview: {
        Activity_desc: 'Track member activity in your community',
        Search_Username_ID: 'Search Username or ID',
        Search_By_ID: 'Search By ID',
        Search_for_Channel: 'Search for Channel',
        placeholder1: 'Click or type here to add items',
        Date_Filter: 'Date Filter',
        Role_Filter: 'Role Filter',
        Last_Login: 'Last Login',
        Custom: 'Custom',
        Category: 'Category',
        Chart_Channel: 'Chart Channel',
        Total_Members: 'Total Members',
        Total_Members_desc: 'The member count of the community.',
        New_Members: 'New Members',
        New_Members_desc: 'The new member count of the community.',
        Daily_Visited_Members: 'Daily Visited Members',
        Daily_Visited_Members_desc: 'The daily visited member count of the community.',
        Total_active_members: 'Total of active members',
        Total_active_members_desc: 'The daily active members of the community.',
        Daily_active_members: 'Daily % of active members',
        Daily_active_members_desc: 'The daily visited member percents of the community.',
        Messages_desc: 'The global message count of the community.',
        Top_Members: 'Top Members',
        Export_CSV: 'Export to CSV',
        Top_Channels: 'Top Channels',
        GM_desc: 'The global message count of the community.',
        Channel_Breakdown: 'Channel Breakdown',
        Percentage: 'Percentage',
        Member_Breakdown: 'Member Breakdown',
        Begin_typing_placeholder: 'Begin typing to search...',
        Daily_Active_Numbers: 'Daily Active Numbers',
    },
    Audit_View: {
        Filter_by_User: 'Filter by User',
        Filter_by_Action: 'Filter by Action',
        IP_address: 'from IP address',
        no_log_history: 'There are no logs to display',
    },
    Leaderboard: {
        Your_Progress: 'Your Progress',
        Rank: 'Rank',
        How_to_earn: 'How to earn',
        Tiers_Overview: 'Tiers Overview',
        All_Time: 'All Time',
        Yearly: 'Yearly',
        Monthly: 'Monthly',
        Weekly: 'Weekly',
        Daily: 'Daily',
        User: 'User',
        Tier: 'Tier',
        Level: 'Level',
        desc: 'This is how you appear in channels!',
        Displayed_Badges: 'Displayed Badges',
        desc1: 'Select up to 3 badges to display',
        No_badge_desc: 'No badges to display',
        Earned: 'Earned',
        Applied: 'Applied',
        Apply: 'Apply',
        Apply_for_badge: 'Apply for badge',
        Apply_for_badge_desc: 'The admins will have to approve your request before you acquire this badge.',
        Message_to_admins: 'Message to admins',
        Attach_Files: 'Attach Files',
        Upload_Files: 'Upload Files',
        files_uploaded: 'files uploaded',
        choose_display_badges: 'Choose up to 3 display badges',
        Quote: 'QUOTE',
        Message: 'Message',
        Add_Friend: 'Add Friend',
        All_Badges: 'All Badges',
        Earned_Badges: 'Earned Badges',
        Applied_Badges: 'Applied Badges',
    },
    Billing: {
        Loading: 'Loading...',
        billing_details: 'billing details',
        desc: 'Welcome to Community Plans! Here, you can keep track of your subscriptions, usage limits and add-ons.',
        sub_desc: 'You may also choose to buy more add-ons, view your invoices or edit your payment methods!',
        Cancel_Subscription: 'Cancel Subscription',
        Subscription_Cancelled: 'Subscription Cancelled',
        Ends: 'Ends',
        Total_Members: 'Total Members',
        Active_Members: 'Active Members in your community',
        View_member_statistics: 'View member statistics',
        View_Details: 'View Details',
        here: 'here',
        Your_yearly_bill_is: 'Your yearly bill is',
        Next_Billing_Date: 'Next Billing Date',
        Your_monthly_bill_is: 'Your monthly bill is',
        Ends_by: 'Ends by',
        Billing_History: 'Billing History',
        Add_Ons: 'Add Ons',
        Active: 'Active',
        Inactive: 'Inactive',
        Purchase_Selected_Add_Ons: 'Purchase Selected Add-Ons',
        Free_plan_desc: 'These Add-Ons are included in the free plan',
        Upgrade: 'Upgrade',
        Upgrade_plan_desc: 'These Add-Ons are only purchasable when you upgrade your server to the premium plan.',
        Upgrade_now: 'Upgrade Now',
        Selected: 'Selected',
        Select: 'Select',
        Cancelled: 'Cancelled',
        Cancel: 'Cancel',
        No_Add_Ons_purchased: 'No Add-Ons purchased',
        Add_Ons_purchased: 'Add-Ons purchased',
        Add_Ons_purchased_desc: 'Billing Cycle starts on the 1st of each month, your first-time payment will be prorated till end of current month',
        Proceed_to_Payment: 'Proceed to Payment',
        Back_to_Add_Ons: 'Back to Add-Ons',
        UPGRADE_COMMUNITY: 'UPGRADE COMMUNITY',
        Upgrade_desc: 'Choose between monthly and annual plans and purchase Add-Ons for your community',
        Back_to_Billing_Details: 'Back to Billing Details',
        Off: 'Off',
        USD_per_month: 'USD per month',
        Billed_Annually: 'Billed Annually',
        Billed_Monthly: 'Billed Monthly',
        What_included: "What's Included",
        Unlimited_Members: 'Unlimited Members',
        Customisable_Add_Ons: 'Customisable Add-Ons',
        All_Basic_Features: 'All Basic Features',
        '4_Channel_Types': '4 Channel Types',
        Theme_Customization: 'Theme Customization',
        CNAME: 'CNAME',
        Rules_and_Disclaimers: 'Rules and Disclaimers',
        Censorship: 'Censorship',
        Moderation_Tools: 'Moderation Tools',
        Analytics: 'Analytics',
        Saved_Payment_Methods: 'Saved Payment Methods',
        Ending_with: 'Ending with',
        Expires: 'Expires',
        New_Payment_Method: 'New Payment Method',
        Card: 'Card',
        Card_Number: 'Card Number',
        Invalid_Card_Number: 'Invalid Card Number',
        Name_on_Card: 'Name on Card',
        Enter_on_Card_Name: 'Enter on Card Name',
        Expiry: 'Expiry',
        Invalid_Expiry: 'Invalid Expiry',
        CVC: 'CVC',
        Invalid_CVC: 'Invalid CVC',
        Addon_purchase: 'Add-Ons Purchase',
        Pricing_Breakdown: 'Pricing Breakdown',
        Premium_Plan: 'Premium Plan',
        Prorated_remaining: 'Prorated for remaining',
        days: 'days',
        MONTHLY: 'MONTHLY',
        Invalid_coupon_code: 'Invalid coupon code, please try again',
        Coupon_Code_Applied: 'Coupon Code Applied',
        Change_Code: 'Change Code',
        Total_Payable: 'Total Payable',
        billing_cycles: 'Annual billing cycles start on the date of first payment.',
        billing_cycles_desc: 'Monthly billing cycles start on the 1st of each month, your first-time payment will be prorated till end of current month',
        Make_Payment: 'Make Payment',
        Your_premium_plan: 'Your Premium plan will be cancelled at the end of your billing period on',
        Keep_Subscription: 'Keep Subscription',
        Your_Premium_subscription: 'Your Premium subscription has been successfully cancelled.',
        'Select_AI_Translation_Add-On': 'Select AI Translation Add-On',
        Translation_token_pack: 'Translation Token Packs can only be purchased together with the AI Translation Add-On',
        Translation_token_packs: 'Translation Token Packs Add-On',
        Translation_token_pack_existed: 'There are some Translation Token Pack in your plan. Please cancel them firstly',
        Okay: 'Okay',
        Cancel_add_ons: 'Cancel Add-Ons',
        Premium: 'Premium',
        Free: 'Free',
        monthly_title: 'Monthly',
        annual_title: 'Annual',
        Enter_Name: 'Enter Name',
        Enter_Code: 'Enter your coupon code here',
        Plan_desc: 'A highly customizable plan that helps you start growing your Community.',
        Spec1: 'Maximum 10 Active Users',
        Spec2: 'All Basic Features',
        Spec3: 'Select Add-on Features',
        Spec4: 'Unlimited Members',
        Spec5: 'Customisable Add-Ons',
        Maximum_10_Active_Users: 'Maximum 10 Active Users',
        'Select_Add-on_Features': 'Select Add-on Features',
        'Customisable_Add-Ons': 'Customisable Add-Ons',
        mobile_title: 'Custom Mobile App',
        mobile_desc: 'Custom mobile apps benefit from sending notifications more effectively to your community. Whether it is Android or iPhone – Genesiv supports them both.',
        mobile_long_desc: '<div><p>Get your very own branded custom mobile app. It’s important for your community members to have access to your community at their fingertips – and there’s no better way to achieve that than with your very own app.</p><p>Custom mobile apps benefit from sending notifications more effectively to your community. Whether it is Android or iPhone – Genesiv supports them both.</p></div>',
        desktop_title: 'Custom Desktop App',
        desktop_desc: 'Obtain your own branded Desktop app that you can freely design and distribute to your community. Available for Windows and Mac.',
        desktop_long_desc: '<div><p>Get your very own custom branded desktop app to get out of that crowded chrome tab crowd – live on the desktop of your community members. Genesiv desktop apps currently support windows, Mac and Linux desktops.</p><p>Our desktop app also supports App Protocols and Deeplinks (basically to launch your app within another app such as a web browser).</p></div>',
        voice_chat_title: 'Voice Chat',
        voice_chat_desc: 'Crystal-clear audio channels allow for live voice calls and group chats!',
        voice_chat_long_desc: '<div><p>The voice chat feature allows you to create different chatrooms where you can talk with other members via voice. Anyone who strolls into the room (if they have the permission to) will be able to join the discussion.</p><p>The ability for users to even use voice chat can be controlled of a “role” level – meaning that you can configure your community to only allow certain users to be able to utilise the voice chats while the others would remain as listeners.</p></div>',
        video_chat_title: 'Video Chat',
        video_chat_desc: 'Video channels allow for video calls and screen sharing for your community!',
        video_chat_long_desc: '<div><p>Video chat channels allow your community to jump into different chat rooms and have a conversation with each other.</p><p>These channels act like perpetually opened chatrooms where anyone can stroll in (provided they have permission to) and have a chat with whoever else is within the room at that point in time.&nbsp;</p></div>',
        upload_limit_title: 'Upload Limit',
        upload_limit_desc: 'Increase file upload limit from 10MB to 50MB and allow members to share larger files in your community.',
        upload_limit_long_desc: '<div><p>Increase file upload limit from 10MB to 50MB and allow members to share larger files in your community.&nbsp;</p></div>',
        chart_title: 'Chart Channels',
        chart_desc: 'Chart channels allow you to integrate interactive investment charts into your community.',
        gamification_title: 'Gamification',
        gamification_desc: 'Boost community engagement with Genesiv’s gamification features. Allow your users to earn XP, Level Up, earn Badges, and more!',
        gamification_long_desc: '<div><p>Gamification helps you drive community engagement and interactions in a fun and enjoyable way. Reward users with XP (Experience Points) as they perform various tasks such as:</p><ul><li>Sending messages</li><li>Reacting to messages</li><li>Replying messages</li><li>Daily Logins</li></ul><p>You can also award custom badges that users can display beside their names in the chatrooms and the leaderboard.</p><p>When used properly, gamification helps give your community members the nudge to becoming a thriving and active community.</p></div>',
        emoji_title: 'Custom Emoji',
        emoji_desc: 'Upload emojis of your choice and use them everywhere in your community, from messages to reactions!',
        emoji_long_desc: '<div><p>Custom Emojis are a great way to add a dose of brand-building into your community. Genesiv allows you to upload both image and animated custom emojis to spice up the communication between your members.</p><p>Custom Emojis have been shown to subtly build brand loyalty by letting your members use brand-exclusive emojis. They have also been shown to improve community retention and engagement.</p></div>',
        banner_title: 'Banners',
        banner_desc: 'Genesiv allows you to create custom banners to appear to different groups of users - allowing you to customize it to a role and channel level.',
        banner_long_desc: '<div><p>Banners appear at the top of your community page and are a great way to do mass announcements or even promotions. They even allow you to add external links that your community members can be directed to.</p><p>Banners also allows you to select specific channels and users that you’d like the banner to appear to. Its smart scheduling feature allows you to set start and end dates whilst tracking the number of views and clicks that each banner gets.</p><p></p></div>',
        webhook_title: 'Webhooks',
        webhook_desc: 'Genesiv comes with extensive incoming and outgoing webhooks that makes sending useful information to various apps easy.',
        webhook_long_desc: '<div><p>Webhooks are a great way to get Genesiv to send and receive information with external applications.</p><p>Incoming webhooks allow you to receive information and display it neatly to your community members. Some examples of these are:</p><ul><li>Update your community when there’s a new Instagram post.</li><li>Send tweets from different users into the channels.</li><li>Post the latest links to your youtube or tiktok videos.</li></ul><p>Outgoing webhooks allow you to send information about your Genesiv server out to other applications. Some external webhooks that you can use are:</p><ul><li>Whenever a new user joins your server</li><li>Extract messages sent in any (or all) channels</li><li>Detect if a message is sent by a specific user</li><li>and many more!</li></ul><p>When harnessed properly, webhooks allow you to easily integrate your community with your other apps.</p></div>',
        login_api_title: 'Login API & Wordpress SSO',
        login_api_desc: 'Automatically log users in from your WordPress website, and assign them relevant roles based on pre-set criteria.',
        login_api_long_desc: '<div><p>Our Login API allows you to create a Single Sign-On (SSO) experience from within your app. At the click of a single button, your users can be taken to your community, registered, logged in and assigned the correct role.</p><p>Our Wordpress Plugin also allows you to create an SSO experience with your existing database of users. Easily map the roles on Wordpress with the roles in your Genesiv community.</p><p>Our documentation also explains how you can even select the “display name” which your users will get once they log in via your SSO.</p></div>',
        embed_channel_title: 'iFrame & Embed Channel',
        embed_channel_desc: 'Embed websites, videos, playlists and more directly into your channel! Really useful in integrating external content directly into your community.',
        widget_title: 'Channel Widgets',
        widget_desc: "Embed your channel onto external websites through a customizable HTML widget. Let the world get a glimpse into what's going on in your community.",
        ai_translation_title: 'AI Translation',
        ai_translation_desc: 'Allow community members to translate messages in your community into their preferred language. Includes 100,000 tokens every month.',
        ai_translation_pack_s_title: 'Translation Token Pack (S)',
        ai_translation_pack_s_desc: 'Includes 1,000,000 tokens per month. This translates to roughly 200,000 words.',
        ai_translation_pack_m_title: 'Translation Token Pack (M)',
        ai_translation_pack_m_desc: 'Includes 10,000,000 tokens. This translates to roughly 2,000,000 words.',
        ai_translation_pack_l_title: 'Translation Token Pack (L)',
        ai_translation_pack_l_desc: 'Includes 50,000,000 tokens. This translates to roughly 10,000,000 words.',
        ai_translation_pack_xl_title: 'Translation Token Pack (XL)',
        ai_translation_pack_xl_desc: 'Includes 100,000,000 tokens. This translates to roughly 20,000,000 words.',
        Step1: 'Choose a Billing Frequency',
        Step2: 'Choose Add-Ons',
        Step3: 'Review and Pay',
        Addon_Preview: 'Add-On Preview',
        Reactive: 'Reactive',
        'Purchase_Add-On': 'Purchase Add-On',
        Add_to_Selection: 'Add to Selection',
        Upgrade_to_Premium: 'Upgrade to Premium',
        Purchase_Successful: 'Purchase Successful',
        Purchase_Success_desc: 'Thank you for your purchase! You will receive a confirmation email containing your invoice shortly.',
        Payment_Error: 'Payment Error',
        Payment_Error_desc: 'Something went wrong with your payment, please try again.',
        Try_Again: 'Try Again',
        'View_all_Add-Ons': 'View all Add-Ons',
        Edit_Payment_Method: 'Edit Payment Method',
        Upcoming_Billing_Details: 'Upcoming Billing Details',
        Date: 'Date',
        Amount: 'Amount',
        Frequency: 'Frequency',
        View_Download_Invoice: 'View / Download Invoice',
        Cancel_Confirm: 'Are you sure you want to cancel your <bold>hello</bold> add-on?',
        Cancel_Confirm_desc: 'This will take effect on <bold>hello</bold> once the current billing cycle ends.',
        Cancel_Confirm_desc1: 'Please take note that if you cancel <bold>hello</bold> Add-On, you will lose access to all features related to the Add-On.',
        Keep_addon: 'Keep Add-On',
        Cancel_addon: 'Cancel Add-On',
    },
    Custom_Mobile_App: {
        Custom_Mobile_App: 'Custom Mobile App',
        Description: 'Obtain your own branded app that you can freely design and distribute to your community.',
        iOS: 'iOS',
        iOS_text: 'Create your app to publish on the App Store.',
        Last_Request: 'Last Request',
        Status: 'Status',
        Download_Link: 'Download Link',
        Android: 'Android',
        Android_text: 'Create your app to publish on the Play Store.',
        Create_iOS_App: 'Create iOS App',
        App_name: 'App name',
        Enter_your_App_name: 'Enter your App name',
        Mobile_App_Icon: 'Mobile App Icon',
        APP_Icon_Size_Text: 'Minimum image size is 1024 x 1024 px',
        Launch_Screen: 'Launch_Screen',
        Launch_Screen_Size_Text: 'Minimum image size is 1500 x 2688 px',
        iOS_App_Setup: 'App Store Setup',
        iOS_Description: 'Connect your app to an Apple Developer Account.',
        Publish_Under_Genesiv: 'Publish Under Genesiv',
        Publish_Under_Genesiv_Text: 'The app will be published under Genesiv’s Apple Account.',
        Bundle_ID: 'Bundle ID',
        Team_ID: 'Team ID',
        Team_ID_Placeholder: 'Enter your Team ID',
        iPhone_Distribution_Certificate_Password: 'iPhone Distribution Certificate Password',
        Upload: 'UPLOAD',
        iPhone_Distribution_Placeholder: 'Enter Certificate Password',
        iPhone_Distribution_Certificate: 'iPhone Distribution Certificate',
        iPhone_Provisioning_Profile: 'iPhone Provisioning Profile',
        Firebase_Setup: 'Firebase Setup',
        Firebase_Setup_Text: 'Connect to Firebase for push notifications and social login.',
        Google_Firebase: 'Google Firebase Server Key',
        Google_Firebase_Placeholder: 'Enter Server Key',
        Google_Firebase_Configuration: 'Google Firebase Configuration',
        No_file_uploaded: 'No file uploaded',
        Save_as_Draft: 'Save as Draft',
        Submit: 'Submit',
        Learn_More: 'Learn More',
        Create_Android_App: 'Create Android App',
        Delete_Image: 'Delete Image',
        Upload_Image: 'Upload Image',
        Frequently_Asked_Description: 'If you can’t find the answer you are looking for, feel free to contact us.',
        Frequent_Question_1: 'How long does it take to set up the app?',
        Frequent_Question_2: 'How will the app be updated?',
        Frequent_Question_3: 'How often can I update the app?',
        Frequent_Question_4: 'What happens when I cancel my Mobile App Add-On subscription?',
    },
    Download_desktop: {
        Download_desktop: 'Download App',
        Desktop: 'Desktop',
        Mobile: 'Mobile',
    },
    Chart_Setting: {
        topToolbar_settings: 'Settings',
        topToolbar_indicators: 'Indicators',
        topToolbar_create_analysis: 'Create Analysis',
        topToolbar_layout_load: 'Load Layout',
        topToolbar_layout_create: 'New Layout',
        topToolbar_layout_rename: 'Rename Layout',
        topToolbar_layout_duplicate: 'Duplicate Layout',
        topToolbar_layout_delete: 'Delete Layout',
        topToolbar_share: 'Share',
        topToolbar_symbol_search: 'Symbol Search1',
        chartSetting_title: 'Chart Settings',
        chartSetting_dark_theme: 'Dark Theme',
        chartSetting_type_title: 'Chart Type',
        chartSetting_type_candle: 'Candles',
        chartSetting_type_line: 'Line',
        chartSetting_type_heiken: 'Heiken Ashi',
        chartSetting_colors_title: 'Colors',
        chartSetting_colors_backgroundColor: 'Background Color',
        chartSetting_colors_body: 'Body',
        chartSetting_colors_border: 'Borders',
        chartSetting_colors_wick: 'Wicks',
        chartSetting_colors_rising: 'Rising',
        chartSetting_colors_falling: 'Falling',
        chartSetting_colors_line: 'Line',
        chartSetting_defaultLabel: 'DEFAULT',
        chartSetting_actions_cancel: 'Cancel',
        chartSetting_actions_save: 'Save',
        layoutTemplate_layout_details: 'LAYOUT DETAILS',
        layoutTemplate_delete_confirm: 'Do you really want to delete?',
        layoutTemplate_yes: 'Yes',
        layoutTemplate_no: 'No',
        layoutTemplate_alpha: 'ALPHABETICAL',
        layoutTemplate_createdAt: 'DATE CREATED',
        layoutTemplate_updatedAt: 'DATE EDITED',
        layoutTemplate_lastEdited: 'Last Edited',
        layoutTemplate_sortBy: 'SORT BY',
        layoutTemplate_order: 'ORDER',
        layoutTemplate_asc: 'Ascending',
        layoutTemplate_desc: 'Descending',
        layoutTemplate_newName: 'New Name',
        layoutTemplate_rename_confirm: 'Do you want to rename the layout to?',
        layoutTemplate_same_name_error: 'There is already a layout with the same name.',
        layoutTemplate_name_changed: 'Layout name changed!',
        layoutTemplate_layout_duplicated: 'Layout duplicated!',
        layoutTemplate_input_error: 'Input Error',
        layoutTemplate_same_name_existed: 'The name is same as orignal_ Please enter another name',
        layoutTemplate_blank_name: 'Please enter layout name',
        layoutTemplate_layout_name: 'Layout Name',
        layoutTemplate_action_undone: 'This action cannot be undone.',
        layoutTemplate_share: 'Share',
        layoutTemplate_invite_someone: 'Invite someone',
        layoutTemplate_send_invite: 'Send Invite',
        layoutTemplate_can_view: 'can view',
        layoutTemplate_can_edit: 'can edit',
        layoutTemplate_remove: 'Remove',
        layoutTemplate_anyone_link: 'Anyone with link can',
        layoutTemplate_only_invited: 'Only people invited to this layout',
        layoutTemplate_copy_link: 'Copy Link',
        layoutTemplate_done: 'Done',
        layoutTemplate_link_copied: 'Link Copied!',
        actions_cancel: 'Cancel',
        actions_save: 'Save',
        actions_ok: 'Ok',
        actions_yes: 'Yes',
        actions_no: 'No',
        actions_delete: 'Delete',
        times_M1: '1 minute',
        times_M5: '5 minutes',
        times_M15: '15 minutes',
        times_M30: '30 minutes',
        times_H1: '1 hour',
        times_H2: '2 hours',
        times_H4: '4 hours',
        times_D: '1 Day',
        times_W: '1 Week',
        times_M: '1 Month',
        shapes_cursor_crosshair: 'Crosshair',
        shapes_cursor_dot: 'Dot',
        shapes_cursor_arrow: 'Arrow',
        'shapes_h-ray': 'Horizontal Ray',
        shapes_h_ray: 'Horizontal Ray',
        shapes_arrow: 'Arrow',
        'shapes_t-line': 'Trend Line',
        shapes_t_line: 'Trend Line',
        shapes_ray: 'Ray',
        shapes_parallel: 'Parallel Channel',
        shapes_fibonacci: 'Fibonacci Retracement',
        shapes_fib_extend: 'Fib Extension',
        shapes_brush: 'Brush',
        shapes_highlighter: 'Highlighter',
        shapes_rect: 'Rect',
        shapes_text: 'Text',
        shapes_callout: 'Callout',
        shapes_price_label: 'Price Label',
        shapes_price_note: 'Price Note',
        shapes_xabcd: 'XABCD Pattern',
        shapes_cypher: 'Cypher Pattern',
        shapes_abcd: 'ABCD Pattern',
        shapes_tri_pattern: 'Triangle Pattern',
        shapes_three_pattern: 'Three Drives Pattern',
        shapes_head_shoulder: 'Head & Shoulders',
        shapes_impulse_wave: 'Elliott Impulse Wave(12345)',
        shapes_triangle_wave: 'Elliott Triangle Wave(ABCDE)',
        shapes_triple_wave: 'Elliott Triple Combo Wave (WXYXZ)',
        shapes_elliott_triple: 'Elliott Triple Combo Wave (WXYXZ)',
        shapes_correction_wave: 'Elliott Correction Wave (ABC)',
        shapes_double_wave: 'Elliott Double Combo Wave (WXY)',
        shapes_analysis_manager: 'Analysis Manager',
        shapes_analysis: 'Analysis',
        shapes_trade: 'Create Idea',
        shapes_weak_magnet: 'Weak Magnet',
        shapes_strong_magnet: 'Strong Magnet',
        shapes_magnet_snap: 'Magnet Mode snaps drawings placed near price bars to the closest OHLC value',
        disabled_preview_mode: 'Disabled in preview mode',
        delete_all: 'Delete All',
        object_layer: 'Object Layer',
        shapes_object_tree: 'Object Tree',
        shapes_close: 'Close',
        shapes_magnet_is_on: 'Magnet Mode is ON',
        shapes_drawing_anchor: 'Drawing anchors will be snapped to the closest OHLC values of nearby bars',
        shapeToolbar_templates: 'Templates',
        shapeToolbar_apply_default: 'Apply Default Drawing Template',
        shapeToolbar_shape_template: 'Shape Template',
        shapeToolbar_save_as: 'Save Drawing Tempalte As...',
        shapeToolbar_as_default: 'Default Template',
        shapeToolbar_font_size: 'Font Size',
        shapeToolbar_font_color: 'Font Color',
        shapeToolbar_line_color: 'Line Color',
        shapeToolbar_background_color: 'Background Color',
        shapeToolbar_line_thickness: 'Line Thickness',
        shapeToolbar_line_style: 'Line Style',
        shapeToolbar_start_arrow_style: 'Start Arrow Style',
        shapeToolbar_end_arrow_style: 'End Arrow Style',
        shapeToolbar_setting: 'Setting',
        shapeToolbar_arrange: 'Arrange',
        shapeToolbar_bring_to_front: 'Bring to Front',
        shapeToolbar_bring_to_back: 'Send to Back',
        shapeToolbar_bring_forward: 'Bring Forward',
        shapeToolbar_send_backward: 'Send Backward',
        shapeToolbar_clone: 'Clone',
        shapeToolbar_lock: 'Lock',
        shapeToolbar_visibility: 'Visibility',
        settingModal_style: 'Style',
        settingModal_coordinates: 'Coordinates',
        settingModal_templates: 'Templates',
        settingModal_show_price: 'Show Price',
        settingModal_show_text: 'Show Text',
        settingModal_text_alignment: 'Text Alignment',
        settingModal_top: 'Top',
        settingModal_middle: 'Middle',
        settingModal_bottom: 'Bottom',
        settingModal_extend_left_line: 'Extend Left Line',
        settingModal_extend_right_line: 'Extend Right Line',
        settingModal_stats_position: 'Stats Position',
        settingModal_show_price_range: 'Show Price Range',
        settingModal_show_bar_range: 'Show Price Range',
        settingModal_show_date_time_range: 'Show Date/Time Range',
        settingModal_show_distance: 'Show Distance',
        settingModal_show_angle: 'Show Angle',
        settingModal_always_show_stats: 'Always Show Stats',
        settingModal_level_line: 'Level Line',
        settingModal_labels: 'Labels',
        settingModal_extend_left_lines: 'Extend Left Lines',
        settingModal_extend_right_lines: 'Extend Right Lines',
        settingModal_single_color: 'Single Color',
        settingModal_background: 'Background',
        settingModal_reverse: 'Reverse',
        settingModal_prices: 'Prices',
        settingModal_levels: 'Levels',
        settingModal_percents: 'Percents',
        settingModal_border: 'Border',
        settingModal_channel: 'Channel',
        settingModal_text_wrap: 'Text Wrap',
        settingModal_wave: 'Wave',
        settingModal_line_style: 'Line Style',
        settingModal_price: 'Price',
        settingModal_bar: 'Bar',
        settingModal_template_name: 'Template Name',
        settingModal_save_template_as: 'Save Template as...',
        indicatorSetting_title: 'Setting of ',
        indicatorSetting_inputs: 'Inputs',
        indicatorSetting_save_as_default: 'Save as default',
        indicatorSetting_reset_setting: 'Reset Setting',
        indicatorSetting_down: 'Down',
        indicatorSetting_length: 'Length',
        indicatorSetting_source: 'Source',
        indicatorSetting_high: 'High',
        indicatorSetting_low: 'Low',
        indicatorSetting_close: 'Close',
        indicatorSetting_offset: 'Offset',
        indicatorSetting_plot: 'Plot',
        indicatorSetting_conversion_line_periods: 'Conversion Line Periods',
        indicatorSetting_base_line_period: 'Base Line Periods',
        indicatorSetting_lagging: 'Lagging Span 2 Periods',
        indicatorSetting_displacement: 'Displacement',
        indicatorSetting_conversion_line: 'Conversion Line',
        indicatorSetting_base_line: 'Base Line',
        indicatorSetting_lagging_span: 'Lagging Span',
        indicatorSetting_lead1: 'Lead1',
        indicatorSetting_lead2: 'Lead2',
        indicatorSetting_plots_background: 'Plots Background',
        indicatorSetting_color_0: 'Color 0',
        indicatorSetting_color_1: 'Color 1',
        indicatorSetting_color_2: 'Color 2',
        indicatorSetting_color_3: 'Color 3',
        indicatorSetting_fast_length: 'Fast Length',
        indicatorSetting_slow_length: 'Slow Length',
        indicatorSetting_signal_smoothing: 'Signal Smoothing',
        indicatorSetting_oscillator: 'Oscillator MA Type',
        indicatorSetting_rma: 'RMA',
        indicatorSetting_wma: 'WMA',
        indicatorSetting_sma: 'SMA',
        indicatorSetting_ema: 'EMA',
        indicatorSetting_signal_line: 'Signal Line MA Type',
        indicatorSetting_macd: 'MACD',
        indicatorSetting_signal: 'Signal',
        indicatorSetting_smoothing: 'Smoothing',
        indicatorSetting_upper_band: 'Upper Band',
        indicatorSetting_low_band: 'Low Band',
        indicatorSetting_lower_band: 'Lower Band',
        indicatorSetting_rsi: 'RSI',
        indicatorSetting_atr: 'ATR',
        analysis_1st_support: '1st Support',
        analysis_2nd_support: '2nd Support',
        analysis_1st_resistance: '1st Resistance',
        analysis_2nd_resistance: '2nd Resistance',
        analysis_pivot: 'Pivot',
        analysis_entry: 'Entry',
        analysis_intermediate_resistance: 'Intermediate Resistance',
        analysis_intermediate_support: 'Intermediate Support',
        analysis_downside_confirmation: 'Downside Confirmation',
        analysis_upside_confirmation: 'Upside Confirmation',
        analysis_hide_all: 'Hide All Status',
        analysis_select_status: 'Select Status',
        analysis_analysis_created: 'Analysis Created',
        analysis_preference_adjusted: 'Preference Adjusted',
    },
    Analaysis_Pan: {
        create_type: 'Type',
        analysis: 'Analysis',
        signal: 'Signal',
        direction: 'Direction',
        bullish: 'Bullish',
        bearish: 'Bearish',
        entry_point: 'Entry Point',
        required: 'Required',
        above_current_price: 'Above Current Price',
        at_current_pirce: 'At Current Price',
        below_current_price: 'Below Current Price',
        title: 'Title',
        preference: 'Preference',
        original_comment: 'Original Comment',
        message: 'Message',
        alternative_scenario: 'Alternative Scenario',
        comments: 'Comments',
        cancel: 'Cancel',
        change: 'Change',
        save: 'Save',
        buy: 'Buy',
        sell: 'Sell',
        bearish_analysis: 'Bearish Analysis',
        bullish_analysis: 'Bullish Analysis',
        buy_signal: 'Buy Signal',
        sell_signal: 'Sell Signal',
        pivot: 'Pivot',
        entry: 'Entry',
        take_profit: 'Take Profit',
        stop_loss: 'Stop Loss',
        partial_profit: 'Partial Profit',
        intermediate_resistance: 'Intermediate Resistance',
        intermediate_support: 'Intermediate Support',
        '1st_support': '1st Support',
        '1st_resistance': '1st Resistance',
        live_price: 'Live Price',
        risk: 'Risk',
        rewards: 'Rewards',
        Pending: 'Pending',
        Open: 'Open',
        Closed: 'Closed',
        last_activity: 'Last Activity',
        view_analysis: 'View Analysis',
        view_signal: 'View Signal',
        view_discussion: 'View Discussion',
        analysis_created: 'Analysis Created',
        signal_created: 'Signal Created',
        type: 'Type',
        expiry: 'Expiry',
        '24_hours': '24 hours (default)',
        select_entry: 'Select your entry',
        good_till_canceled: 'Good till canceled',
        levels: 'Levels',
        general: 'General',
        discussion: 'Discussion',
        create_analysis: 'Create Analysis',
        edit_analysis: 'Edit Analysis',
        create_idea: 'Create Idea',
        edit_idea: 'Edit Idea',
        create_signal: 'Create Signal',
        edit_signal: 'Edit Signal',
        guest_access: 'Guest Access',
        post_to_multi_channels: 'Post to multiple channels',
        allow_desc: 'Allows you to blast your analysis to multiple channels with the same symbol charts. You may blast messages within the same server or across different servers.',
        change_analysis_type: 'Change Analysis Type',
        change_signal_type: 'Change Signal Type',
        change_direction: 'Are you sure you want to change direction?',
        change_analysis_direction: 'Changing the Analysis type will clear entry point, all current levels, title, preference, etc that are on the chart.',
        change_signal_direction: 'Changing the Signal type will clear entry point, all current levels, title, preference, etc that are on the chart.',
        time_a_day_ago: 'a day ago',
        time_days_ago: 'days ago',
        time_an_hour_ago: 'an hour ago',
        time_hours_ago: 'hours ago',
        time_mins_ago: 'mins ago',
        time_just_now: 'just now',
        time_a_minute_ago: 'a minute ago',
        time_a_month_ago: 'a month ago',
        time_months_ago: 'months ago',
        time_a_year_ago: 'a year ago',
        time_years_ago: 'years ago',
        tooltip_react: 'React to this message',
        tooltip_delete_react: 'Delete All Reactions',
        delete_react_desc: 'Are you sure you want to delete all emoji reactions for this message? This action cannot be undone.',
        tooltip_pin: 'Pin',
        tooltip_unpin: 'Unpin',
        tooltip_share: 'Share',
        tooltip_edit: 'Edit',
        tooltip_reply: 'Reply',
        tooltip_reply_thread: 'Reply in Thread',
        tooltip_blast: 'Post to other channels',
        tooltip_delete: 'Delete',
        tooltip_show_translated: 'Show Translated',
        tooltip_show_original: 'Show Original',
        move_cursor_down: '##1 value  cannot be <b> above <b/> the <br/> ##2 value (Move cursor down)',
        move_cursor_up: '##1 value  cannot be <b> below </b> the <br/> ##2 value (Move cursor up)',
        invalidation_value: 'Invalidation Value',
        invalid_value: 'Invalid Value!',
        pivot_error_1: 'Pivot can not be less than the 1st support.',
        pivot_error_2: 'Pivot can not be greater than the 1st resistance.',
        pivot_error_3: 'The Pivot can not be greater than current value.',
        pivot_error_4: 'The Pivot should be same as current value.',
        pivot_error_5: 'The Pivot should be greater than current value.',
        pivot_error_6: 'The Pivot should be greater than current value.',
        pivot_error_7: 'The Pivot should be same as current value.',
        pivot_error_8: 'The Pivot should be less than current value.',
        '1st_support_error1': 'The 1st Support can not be greater than pivot',
        '1st_resistance_error1': 'The 1st Resistance can not be less than pivot',
        '2nd_support_error1': 'The 2nd Support can not be greater than 1st support',
        '2nd_resistance_error1': 'The 2nd Resistance can not be less than 1st Resistance',
        inter_support_error1: 'The Intermediate Support have to be less than pivot',
        inter_support_error2: 'The Intermediate Support have to be greater than 1st support',
        inter_resistance_error1: 'The Intermediate Resistance have to be greater than pivot',
        inter_resistance_error2: 'The Intermediate Resistance have to be less than 1st resitance',
        down_confirm_error1: 'The Downside Confirmation can not be greater than current value',
        down_confirm_error2: 'The Downside Confirmation can not be less than 1st Support',
        up_confirm_error1: 'The Upside Confirmation can not be less than current value',
        up_confirm_error2: 'The Upside Confirmation can not be greater than 1st Resistance',
        select_data_tooltip: 'Select data from chart',
        select_pivot: 'Please select Pivot',
        select_1st_resistance: 'Please select 1st Resistance',
        select_1st_support: 'Please select 1st Support',
        signal_select_entry: 'Please select Entry',
        select_take_profit: 'Please select Take Profit',
        select_stop_loss: 'Please select Stop Loss',
    },
    Top_Menu: {
        build_your_dream: 'Build Your Dream Community!',
        direct_messages: 'Direct Messages',
        personal_chart: 'Personal Charts',
        app_settings: 'App Settings',
        my_account: 'My Account',
        servers_explorer: 'Servers Explorer',
    },
    Forum_Dm: {
        select_chat: 'Select a chat to start messaging',
        no_active_dms: 'No Active DMs',
        no_message: 'NO MESSAGES YET',
        no_init_conversation: 'Looks like you haven’t initiated a conversation with anyone yet.',
        about_me: 'About Me',
        search_conversation: 'Search Conversations',
        no_results_found: 'No results found',
        search_chat: 'Search Chat',
        pinned_message: 'Pinned Message',
        pinned_messages: 'Pinned Messages',
        bot: 'BOT',
        webhook: 'WEBHOOK',
        jump: 'Jump',
        no_message_from: 'You have no new message from ',
        send_message_desc: 'Send a message to begin your conversation.',
        loading: 'Loading...',
        pin_message: 'Pin Message',
        pin_message_confirm: 'Would you like to pin this message?',
        cancel: 'Cancel',
        pin: 'Pin',
        unpin_message: 'Unpin Message',
        unpin_message_confirm: 'Would you like to unpin this message?',
        unpin: 'Unpin',
        no_description_yet: 'No Description Yet',
        no_media_yet: 'No Media Yet',
        no_link_yet: 'No Link Yet',
    },
    Linking_Object: {
        title: 'Selected Objects',
        desc: 'Click on any objects on the chart you wish to link to this highlighted text',
        confirm: 'Confirm',
        lines: 'Lines',
    },
    Bot_Notification: {
        current_price: 'Current Price',
        pending: 'Pending',
        open: 'Open',
        closed: 'Closed',
        support_adjusted: 'Support Adjusted',
        resistance_adjusted: 'Resistance Adjusted',
        pivot_adjusted: 'Pivot Adjusted',
        title_edited: 'Title Edited',
        comments_edited: 'Comments Edited',
        preference_edited: 'Preference Edited',
        scneario_edited: 'Alternative Scneario Edit',
        type_edited: 'Analysis Type Edited',
        analysis_edited: 'Analysis Edited',
        support_reached: 'Support Reached',
        resistance_reached: 'Resistance Reached',
        pivot_reached: 'Pivot Reached',
        analysis_expired: 'Analaysis Expired',
        support: 'Support',
        resistance: 'Resistance',
        pivot: 'Pivot',
        support_adjusted_noti: '##1 has adjusted the ##2 value from ##3 to ##4',
        title_adjusted_noti: '##1 has adjusted the title ##2 to ##3',
        comment_adjusted_noti: "##1 has edited the analysis' comments.",
        preference_adjusted_noti: "##1 has edited the analysis' preference.",
        scenario_adjusted_noti: "##1 has edited the analysis' alternative scenario.",
        type_adjusted_noti: "##1 has edited the analysis' type. The analysis type is now ##2",
        support_reached_noti: 'Aanalysis is now closed, dropping ##1 from ##2 to ##3',
        support_reached_noti2: 'Analysis has reached its target!. It moved ##1 from ##2 to ##3',
        resistance_reached_noti: 'Aanalysis has risen ##1 from ##2 to ##3',
        resistance_reached_noti2: 'Analysis has reached its target!. It moved  from ##2 to ##3',
        analysis_expired_noti: 'Analysis has been closed due to expiration',
        isupport_reached_noti: 'Current price has reached the Intermediate Support target! It moved ##1 from ##2 to ##3',
        isupport_reached_noti2: 'Current price has reached the Intermediate Support target, dropping ##1 pips from ##2 to ##3',
        iresistance_reached_noti: 'Current price has reached the Intermediate Resistance, dropping ##1 from ##2 to ##3',
        iresistance_reached_noti2: 'Current price has reached the Intermediate Resistance target!. It moved ##1 from ##2 to ##3',
        pivot_reached_noti: 'Price has reached the pivot at ##1. The Analysis is now ##2',
    },
    Redemption_Store: {
        Title: 'Store',
        Desc: 'Allow members to redeem rewards from the community store.',
        Enable_Text: 'To enable store, please turn on',
        In_Game_Reward_System: 'in-game reward system',
        Enable_Store: 'Enable Store',
        Enable_Store_Desc: 'Activate the store for users to redeem rewards.',
        Store_Name: 'Store Name',
        Store_Name_Desc: 'Customise the name of the store.',
        Banner_Display: 'Banner Display',
        Banner_Display_Desc: 'Customise and display a banner across the store.',
        Recommended_Banner_Size: 'Recommended size 970 x 250px',
        Rewards: 'Rewards',
        All_Rewards: 'All Rewards',
        Rewards_Desc: 'Create and categorise rewards in your store.',
        All_Rewards_Desc: 'You may drag and drop rewards into the desired categories.',
        Select_Reward: 'Select all rewards/categories',
        Customisation: 'Customisation',
        Customisation_Desc: 'Customise the display and settings for your rewards.',
        Title_Category: 'Title of Category',
        Title_Category_Desc: 'Provide a name for your category.',
        Category_Access: 'Category Access',
        Category_Access_Desc: 'Select roles/users who can access the rewards in the category.',
        Delete_Category: 'Delete Category',
        Archive_Category: 'Archive Category',
        Delete_Reward: 'Delete Reward',
        Archive_Reward: 'Archive Reward',
        Delete_Voucher: 'Delete Voucher',
        Delete_Voucher_Desc: 'Are you sure you want to delete the voucher?',
        No_Items: 'There are no items created yet',
        Select_All_Categories_Roles: 'Select all rewards/categories',
        Delete_Selected_Rewards: 'Delete selected rewards ?',
        Delete_Reward_Confirmation: 'Are you sure you want to delete the selected rewards?',
        Type_Of_Reward: 'Type of Reward',
        Description_Of_Reward: 'Description of Reward',
        Customise_Reward: 'Customise the name of the reward',
        Customise_Reward_Displays: 'Customise how your reward displays on the store',
        Image_Upload: 'Image Upload',
        Reward_Price: 'Price of Reward',
        Customise_Reward_Price: 'Customise the price of the reward',
        Active_Vouchers_Available: 'Active Vouchers Available',
        Prioritise_Expiring_Voucher: 'Prioritise issuing vouchers with earlier expiry dates',
        Prioritise_Expiring_Voucher_Desc: 'Vouchers with earlier expiry date will be issued first.',
        Highlight_Expiring_Vouchers: 'Highlight expiring vouchers',
        Highlight_Expiring_Vouchers_Desc: 'Set and highlights expiring vouchers on the store settings.',
        Before_Expiry: 'before voucher expiry',
        Display_Remaining_Quantity: 'Display remaining quantity',
        Display_Remaining_Quantity_Desc: 'Customise whether to display the remaining quantity of the reward.',
        Reward_Access: 'Reward Access',
        Reward_Access_Desc: 'Select roles/users who can access the rewards.',
        Delete_Reward_Confirm: 'Are you sure you want to delete the reward',
        Delete_Category_Confirm: 'Are you sure you want to delete the category',
        Archive_Reward_Confirm: 'Are you sure you want to archive the reward',
        Archive_Reward_Desc: 'You can find your archived categories under Store > Rewards > Archived.',
        Archive_Category_Confirm: 'Are you sure you want to archive the category',
        Delete_Category_Warning: 'All of the rewards under the category will be deleted. This action cannot be undone.',
        Active_Voucher_Code: 'Active Voucher Code',
        Active_Voucher_Code_Desc: 'Input the voucher codes for user redemption.',
        Upload_Voucher: 'Upload Voucher',
        Vouchers_Selected: 'Vouchers Selected',
        Upload_Bulk_Vouchers: 'Upload Bulk Vouchers',
        Add_Multiple_Vouchers: 'Please add multiple vouchers here.',
        Add_Multiple_Vouchers_Desc: 'You may separate voucher codes with commas.',
        Duplicate_Voucher_Error: 'There are duplicate voucher code(s) within the current reward. Please check again.',
        Upload_Voucher_Instruction_1: 'Download the provided template',
        Upload_Voucher_Instruction_2: 'Enter all voucher codes and expiry dates in the correct columns.',
        Upload_Voucher_Instruction_3: 'Ensure that the expiry date is in the format dd/mm/yyyy',
        Upload_Voucher_Instruction_4: 'Once done, save the template and upload it again.',
        Upload_Voucher_Instruction_5: "Finally, select the 'Upload' button",
        Review_Uploaded_Voucher: 'Please review the uploaded vouchers: ',
        Confirm_Voucher_Codes: 'Confirm Voucher Codes',
        Edit_Voucher: 'Edit Voucher',
        Add_Voucher_Codes: 'Add Voucher Codes',
        No_Rewards: 'No Rewards Found.',
        Upload_Voucher_Error: 'There is an error in your file. Please ensure that all voucher codes and expiry dates are correctly written in the respective columns.',
        Payment_Icons_Title: 'Payment Icons',
        Payment_Icons_Desc: 'Customize the icon between different types of payments.',
        Cash_Payment_Icon: 'Cash Payment Icon',
        In_Game_Currency_Icon: 'In-Game Currency Icon',
        Configure_In_Game_Desc: 'Configure in-game currency settings.',
    },
    Item: 'Item',
    Type: 'Type',
    Quantity: 'Quantity',
    Back: 'Back',
    Default: 'default',
    Chart_History: 'Chart History',
    Last_Edit: 'Last Edit',
    Not_yet: 'Not yet',
    Load_More: 'Load More',
    Load_Older: 'Load Older',
    New_label: 'New',
    Edited_on: 'Edited on',
    Replying_to: 'Replying to',
    Send: 'Send',
    Original_message_deleted: 'Original message was deleted.',
    Shared_From: 'Shared From',
    By: 'By',
    Delete_Comment: 'Delete Comment',
    Delete_Comment_desc: 'Do you really want to delete this comment?',
    Filter: 'Filter',
    Clear: 'Clear',
    Share_with: 'Share with',
    All_Symbols: 'All Symbols',
    Any_Date: 'Any Date',
    Exit: 'Exit',
    No_comments: 'No comments yet',
    Apply_Filter: 'Apply Filter',
    Custom_Leaderboard: 'Custom Leaderboard',
    Create_Custom_Leaderboard: 'Create New Custom Leaderboard',
    Edit_Custom_Leaderboard: 'Edit Custom Leaderboard',
    Custom_Leaderboard_desc: 'Create a custom leaderboard that ranks members by a metric of your choice.',
    Custom_Leaderboard_desc1: 'Members can access the custom leaderboard in the hamburger menu.',
    Customize_name_desc: 'Customize the name of the leaderboard. The default name is Custom Leaderboard.',
    Image_Optional: 'Image (Optional)',
    Time_Filter_View: 'Time Filter View',
    Select_Deselect_all: 'Select / Deselect all',
    Rank_By: 'Rank By',
    Rank_By_desc: 'Members will be ranked on the leaderboard based on this metric',
    Displayed_Fields: 'Displayed Fields',
    Displayed_Fields_desc: 'Select fields that you want to display in the custom leaderboard.',
    Exclude_Roles: 'Exclude Roles',
    Exclude_Roles_desc: 'Select roles to exclude from viewing the custom leaderboard.',
    Roles_Users: 'Roles/Users',
    Roles_Users_desc: 'Select roles/users that will be ranked in the custom leaderboard.',
    Select_Role_Users: 'Select Roles or Users',
    Deleted_Comment: 'Deleted Message',
    Current_Level: 'Current Level',
    Badges_Earned: 'Badges Earned',
    Suggested: 'Suggested',
    Click_to_see_Attachment: 'Click to see attachment',
    Click_to_see_Analysis: 'Click to see analysis',
    Click_to_see_Otp: 'Click to see object link',
    Lang_Setting_Title: 'We noticed that you are sending messages in ##1, but your settings are in ##2.',
    Lang_Setting_Confirm: 'Would you like to change your language settings?',
    Lang_Setting_Writing: 'I am writing in',
    Lang_Setting_Reading: 'I am reading in',
    Overwrite_Setting_Reading: 'Reading in',
    Overwrite_Setting_Writing: 'Writing in',
    Lang_Setting_Community: 'Community Settings',
    Editing_Message: 'Editing Message',
    Convert: 'Convert',
    Navigation_Bar: 'Navigation Bar',
    Loading_Animation: 'Loading Animation',
    preset: 'Preset',
    custom: 'Custom',
    Custom_Loading_Animation: 'Custom Loading Animation',
    Import_Custom_Loading_Title: 'Import your Custom Loading Animation',
    Import_Custom_Loading_Desc: 'Import your preferred Custom Loading Animation in Dark / Light Theme.',
    Saved: 'Saved',
    Loading_Incomplete_Modal_Header: 'Incomplete Custom Loading Animation',
    Loading_Incomplete_Modal_Desc: 'There is an error, or the custom loading animation is not saved.',
    Loading_Incomplete_Modal_Helper: 'Please complete your action before proceeding.',
    Options: 'Options',
};
var keywordArray = [];
function getKeywordArray(translationsObject, parent) {
    if (translationsObject === void 0) { translationsObject = defaultTranslations; }
    for (var keyword in translationsObject) {
        if (typeof translationsObject[keyword] === 'string')
            keywordArray.push(parent ? "".concat(parent, ".").concat(keyword) : keyword);
        else
            getKeywordArray(translationsObject[keyword], parent ? "".concat(parent, ".").concat(keyword) : keyword);
    }
}
getKeywordArray();
function setData(data, object, parent) {
    for (var keyword in object) {
        if (typeof object[keyword] === 'string')
            object[keyword] = data[parent ? "".concat(parent, ".").concat(keyword) : keyword];
        else
            object[keyword] = setData(data, object[keyword], parent ? "".concat(parent, ".").concat(keyword) : keyword);
    }
    return object;
}
function translateAll(code, translationsObject) {
    if (code === void 0) { code = 'en'; }
    if (translationsObject === void 0) { translationsObject = defaultTranslations; }
    return __awaiter(this, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, window.translate(keywordArray)];
                case 1:
                    data = _a.sent();
                    setData(data, translationsObject);
                    return [2 /*return*/, translationsObject];
                case 2:
                    error_1 = _a.sent();
                    console.log("Translate \"".concat(error_1, "\" error"));
                    return [2 /*return*/, translateAll(code, translationsObject)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
// @ts-ignore
window.setLanguage = function (languageCode) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, translateAll(languageCode)
                // @ts-ignore
            ];
            case 1:
                _b.sent();
                // @ts-ignore
                (_a = window.setLanguageCodeFunctions) === null || _a === void 0 ? void 0 : _a.forEach(function (func) { return func(languageCode); });
                return [2 /*return*/];
        }
    });
}); };
var LanguageContext = (0, react_1.createContext)({
    translations: defaultTranslations,
    languageCode: '',
});
/** ### Use Translations ###
 * @description This hook can get translated data from translation provider.
 * @example
 * ```jsx
 * const { translations, languageCode } = useTranslations();
 * ```
 * @link Read more about `translations` in `documentation/translations.md`
 */
var useTranslations = function () {
    var context = (0, react_1.useContext)(LanguageContext);
    if (!context)
        throw new Error('useTranslations must be used within a TranslationsProvider');
    return context;
};
exports.useTranslations = useTranslations;
var TranslationsProvider = function (_a) {
    var children = _a.children;
    var _b = __read((0, react_1.useState)(defaultTranslations), 1), translations = _b[0];
    var _c = __read((0, react_1.useState)(''), 2), languageCode = _c[0], setLanguageCode = _c[1];
    (0, react_1.useEffect)(function () {
        // @ts-ignore
        if (window.setLanguageCodeFunctions)
            window.setLanguageCodeFunctions.push(setLanguageCode);
        // @ts-ignore
        else
            window.setLanguageCodeFunctions = [setLanguageCode];
    }, []);
    return (0, jsx_runtime_1.jsx)(LanguageContext.Provider, { value: { translations: translations, languageCode: languageCode }, children: children });
};
/** ### Translations Helper ###
 * @description This function can wrap any react component and provide translations in every child components.
 * @param Component - What component do you want to wrap?
 * @returns New component that already wrapped with translation provider.
 * @example
 * ```jsx
 * angularize(translationsHelper(HomePage), 'home-page', window.angular.module('live-trader'), {})
 * ```
 * @link Read more about `translations` in `documentation/translations.md`
 */
var translationsHelper = function (Component) {
    function ComponentWithTranslationsHelper(props) {
        return ((0, jsx_runtime_1.jsx)(TranslationsProvider, { children: (0, jsx_runtime_1.jsx)(Component, __assign({}, props)) }));
    }
    Object.defineProperty(ComponentWithTranslationsHelper, 'name', { writable: true, value: Component.name });
    return ComponentWithTranslationsHelper;
};
exports.default = translationsHelper;
