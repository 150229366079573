"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.BOT_RESPONSE_LENGTH_MAP = exports.EMPTY_BOT_ACCOUNT = exports.EMPTY_BOT_USER_ACCOUNT = exports.DAYS_OF_WEEK = exports.BOT_ACTIVE_PERIOD_OPTIONS = exports.DELAY_UNIT_OPTIONS = exports.RESPONSE_SCOPE_OPTIONS = exports.EMPATHY_LEVEL_OPTIONS = exports.HUMOUR_LEVEL_OPTIONS = exports.LANGUAGE_STYLE_OPTIONS = exports.RESPONSE_LENGTH_OPTIONS = exports.TONE_OF_VOICE_OPTIONS = exports.LANGUAGE_SETTINGS = exports.NEW_BOT_USER_ACCOUNT_ID = exports.NEW_BOT_ACCOUNT_ID = void 0;
var IBotAccount_1 = require("@/dto/types/ai-users/IBotAccount");
exports.NEW_BOT_ACCOUNT_ID = 'new_bot_account';
exports.NEW_BOT_USER_ACCOUNT_ID = 'new_bot_user_account';
exports.LANGUAGE_SETTINGS = [
    { value: IBotAccount_1.BotLanguageMode.Reply, text: 'Reply according to the language of the message' },
    { value: IBotAccount_1.BotLanguageMode.Custom, text: 'Custom language of AI User' },
];
exports.TONE_OF_VOICE_OPTIONS = [
    { value: IBotAccount_1.BotToneOfVoice.Cheerful, label: 'Cheerful' },
    { value: IBotAccount_1.BotToneOfVoice.Neutral, label: 'Neutral' },
    { value: IBotAccount_1.BotToneOfVoice.Calm, label: 'Calm' },
    { value: IBotAccount_1.BotToneOfVoice.Enthusiastic, label: 'Enthusiastic' },
    { value: IBotAccount_1.BotToneOfVoice.Serious, label: 'Serious' },
];
exports.RESPONSE_LENGTH_OPTIONS = [
    { value: IBotAccount_1.BotResponseLength.Short, label: 'Short (Less than 10 words)' },
    { value: IBotAccount_1.BotResponseLength.Medium, label: 'Medium (10 - 20 words)' },
    { value: IBotAccount_1.BotResponseLength.Long, label: 'Long (More than 20 words)' },
];
exports.LANGUAGE_STYLE_OPTIONS = [
    { value: IBotAccount_1.BotFormality.VeryFormal, label: 'Very Formal' },
    { value: IBotAccount_1.BotFormality.Formal, label: 'Formal' },
    { value: IBotAccount_1.BotFormality.SemiFormal, label: 'Semi-Formal' },
    { value: IBotAccount_1.BotFormality.Casual, label: 'Casual' },
    { value: IBotAccount_1.BotFormality.VeryCasual, label: 'Very Casual' },
];
exports.HUMOUR_LEVEL_OPTIONS = [
    { value: IBotAccount_1.BotHumorLevel.None, label: 'None' },
    { value: IBotAccount_1.BotHumorLevel.Subtle, label: 'Subtle' },
    { value: IBotAccount_1.BotHumorLevel.Moderate, label: 'Moderate' },
    { value: IBotAccount_1.BotHumorLevel.High, label: 'High' },
];
exports.EMPATHY_LEVEL_OPTIONS = [
    { value: IBotAccount_1.BotEmpathyLevel.None, label: 'None' },
    { value: IBotAccount_1.BotEmpathyLevel.Low, label: 'Low' },
    { value: IBotAccount_1.BotEmpathyLevel.Medium, label: 'Medium' },
    { value: IBotAccount_1.BotEmpathyLevel.High, label: 'High' },
];
exports.RESPONSE_SCOPE_OPTIONS = [
    { value: IBotAccount_1.BotResponseScope.RelatedTopics, label: 'Related topics' },
    { value: IBotAccount_1.BotResponseScope.KnowledgeBase, label: 'Knowledge base' },
    { value: IBotAccount_1.BotResponseScope.Greetings, label: 'Greetings' },
    { value: IBotAccount_1.BotResponseScope.GeneralConversations, label: 'General conversations' },
];
exports.DELAY_UNIT_OPTIONS = [
    { value: IBotAccount_1.BotDelayUnit.Seconds, label: 'Secs' },
    { value: IBotAccount_1.BotDelayUnit.Minutes, label: 'Mins' },
];
exports.BOT_ACTIVE_PERIOD_OPTIONS = [
    { value: IBotAccount_1.BotActivePeriod.AllTime, text: 'All Time' },
    {
        value: IBotAccount_1.BotActivePeriod.Custom,
        text: 'Custom',
    },
];
exports.DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
exports.EMPTY_BOT_USER_ACCOUNT = {
    _id: exports.NEW_BOT_USER_ACCOUNT_ID,
    username: '',
    displayName: '',
    firstName: '',
    lastName: '',
    password: '',
    avatar: '',
    roles: [],
    aiEnabled: true,
};
exports.EMPTY_BOT_ACCOUNT = {
    _id: exports.NEW_BOT_ACCOUNT_ID,
    name: 'New AI User',
    enabled: false,
    personaProtection: false,
    userId: null,
    assignedChannels: [],
    interestedTopics: [],
    knowledgeFiles: [],
    communityDescription: '',
    topicOfFocus: '',
    topicOfAvoid: '',
    crawlFiles: [],
    formality: IBotAccount_1.BotFormality.Casual,
    toneOfVoice: [IBotAccount_1.BotToneOfVoice.Cheerful],
    responseLength: [IBotAccount_1.BotResponseLength.Short],
    //----- Default setting for Response -----//
    responseEnabled: false,
    responseScopes: [IBotAccount_1.BotResponseScope.RelatedTopics],
    responseWithMention: false,
    askClarifyingQuestions: false,
    askProbingQuestions: false,
    questionLimit: 0,
    //----- Default setting for Language -----//
    languageMode: IBotAccount_1.BotLanguageMode.Reply,
    language: 'English',
    //----- Default setting for Active Period -----//
    activePeriod: IBotAccount_1.BotActivePeriod.AllTime,
    activeDays: exports.DAYS_OF_WEEK,
    activeTimeZone: 'GMT+8',
    activeTimeFrom: '8PM',
    activeTimeTo: '6AM',
};
exports.BOT_RESPONSE_LENGTH_MAP = (_a = {},
    _a[IBotAccount_1.BotResponseLength.Short] = [0, 10],
    _a[IBotAccount_1.BotResponseLength.Medium] = [10, 20],
    _a[IBotAccount_1.BotResponseLength.Long] = [20, Infinity],
    _a);
