"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectValue = exports.SelectTrigger = exports.SelectSeparator = exports.SelectLabel = exports.SelectItem = exports.SelectGroup = exports.SelectContent = exports.Select = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var SelectPrimitive = __importStar(require("@radix-ui/react-select"));
var lucide_react_1 = require("lucide-react");
var React = __importStar(require("react"));
var utils_1 = require("../../lib/utils");
var Select = SelectPrimitive.Root;
exports.Select = Select;
var SelectGroup = SelectPrimitive.Group;
exports.SelectGroup = SelectGroup;
var SelectValue = SelectPrimitive.Value;
exports.SelectValue = SelectValue;
var SelectTrigger = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, ghost = _a.ghost, props = __rest(_a, ["className", "children", "ghost"]);
    return ((0, jsx_runtime_1.jsxs)(SelectPrimitive.Trigger, __assign({ ref: ref, className: (0, utils_1.cn)('tw-flex tw-h-10 tw-w-full tw-items-center tw-justify-between tw-rounded-md tw-border-customgrey tw-bg-background-6 tw-px-3 tw-py-2 tw-text-[14px] tw-text-text-1 placeholder:tw-text-text-1 focus:tw-outline-none disabled:tw-cursor-not-allowed disabled:tw-opacity-50', ghost ? 'tw-border tw-border-background-7 tw-bg-transparent hover:tw-border-background-7' : '', className) }, props, { children: [children, (0, jsx_runtime_1.jsx)(SelectPrimitive.Icon, { asChild: true, children: (0, jsx_runtime_1.jsx)(lucide_react_1.ChevronDown, { className: 'tw-h-4 tw-w-4 tw-opacity-50' }) })] })));
});
exports.SelectTrigger = SelectTrigger;
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
var SelectContent = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, _b = _a.position, position = _b === void 0 ? 'popper' : _b, props = __rest(_a, ["className", "children", "position"]);
    return ((0, jsx_runtime_1.jsx)(SelectPrimitive.Portal, { children: (0, jsx_runtime_1.jsx)(SelectPrimitive.Content, __assign({ ref: ref, className: (0, utils_1.cn)('tw-relative tw-z-[9999] tw-overflow-hidden tw-rounded-md tw-bg-background-6 tw-text-text-1 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2', position === 'popper' &&
                'data-[side=bottom]:tw-translate-y-1 data-[side=left]:-tw-translate-x-1 data-[side=right]:tw-translate-x-1 data-[side=top]:-tw-translate-y-1', className), position: position }, props, { children: (0, jsx_runtime_1.jsx)(SelectPrimitive.Viewport, { className: (0, utils_1.cn)('tw-p-1', position === 'popper' &&
                    'tw-h-[var(--radix-select-trigger-height)] tw-w-full tw-min-w-[var(--radix-select-trigger-width)]'), children: children }) })) }));
});
exports.SelectContent = SelectContent;
SelectContent.displayName = SelectPrimitive.Content.displayName;
var SelectLabel = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(SelectPrimitive.Label, __assign({ ref: ref, className: (0, utils_1.cn)('tw-py-1.5 tw-pl-8 tw-pr-2 tw-text-sm tw-font-semibold', className) }, props)));
});
exports.SelectLabel = SelectLabel;
SelectLabel.displayName = SelectPrimitive.Label.displayName;
var SelectItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, hideCheck = _a.hideCheck, ghost = _a.ghost, props = __rest(_a, ["className", "children", "hideCheck", "ghost"]);
    return ((0, jsx_runtime_1.jsxs)(SelectPrimitive.Item, __assign({ ref: ref, className: (0, utils_1.cn)('tw-relative tw-flex tw-w-full tw-cursor-pointer tw-select-none tw-items-center tw-rounded-[4px] tw-py-1.5 tw-pr-2 tw-text-[14px] tw-text-text-1 tw-outline-none hover:tw-bg-background-4 focus:tw-bg-background-7 focus:tw-text-text-1 data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50', ghost ? 'hover:tw-bg-background-3 focus:tw-bg-background-3' : '', className, !hideCheck ? 'tw-pl-8' : 'tw-pl-4') }, props, { children: [!hideCheck ? ((0, jsx_runtime_1.jsx)("span", { className: 'tw-absolute tw-left-2 tw-flex tw-h-3.5 tw-w-3.5 tw-items-center tw-justify-center', children: (0, jsx_runtime_1.jsx)(SelectPrimitive.ItemIndicator, { children: (0, jsx_runtime_1.jsx)(lucide_react_1.Check, { className: 'tw-h-4 tw-w-4' }) }) })) : null, (0, jsx_runtime_1.jsx)(SelectPrimitive.ItemText, { children: children })] })));
});
exports.SelectItem = SelectItem;
SelectItem.displayName = SelectPrimitive.Item.displayName;
var SelectSeparator = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return ((0, jsx_runtime_1.jsx)(SelectPrimitive.Separator, __assign({ ref: ref, className: (0, utils_1.cn)('tw-bg-muted -tw-mx-1 tw-my-1 tw-h-px', className) }, props)));
});
exports.SelectSeparator = SelectSeparator;
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
