"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOCIAL_ACTIVITY_NAVIGATIONS = void 0;
var PageEndpoints_1 = require("./PageEndpoints");
exports.SOCIAL_ACTIVITY_NAVIGATIONS = [
    {
        text: 'Facebook',
        link: PageEndpoints_1.PageEndpoints.socialMedia.facebook,
    },
    {
        text: 'Instagram',
        link: PageEndpoints_1.PageEndpoints.socialMedia.instagram,
    },
    {
        text: 'X',
        link: PageEndpoints_1.PageEndpoints.socialMedia.twitter,
    },
    {
        text: 'LinkedIn',
        link: PageEndpoints_1.PageEndpoints.socialMedia.linkedin,
    },
    {
        text: 'Youtube',
        link: PageEndpoints_1.PageEndpoints.socialMedia.youtube,
    },
];
