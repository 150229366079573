"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BotDelayUnit = exports.BotActivePeriod = exports.BotResponseScope = exports.BotEmpathyLevel = exports.BotHumorLevel = exports.BotResponseLength = exports.BotToneOfVoice = exports.BotFormality = exports.BotLanguageMode = void 0;
exports.BotLanguageMode = {
    Reply: 'reply',
    Custom: 'custom',
};
exports.BotFormality = {
    VeryFormal: 'very-formal',
    Formal: 'formal',
    SemiFormal: 'semi-formal',
    Casual: 'casual',
    VeryCasual: 'very-casual',
};
exports.BotToneOfVoice = {
    Cheerful: 'cheerful',
    Neutral: 'neutral',
    Calm: 'calm',
    Enthusiastic: 'enthusiastic',
    Serious: 'serious',
};
exports.BotResponseLength = {
    Short: 'short',
    Medium: 'medium',
    Long: 'long',
    Custom: 'custom',
};
exports.BotHumorLevel = {
    None: 'none',
    Subtle: 'subtle',
    Moderate: 'moderate',
    High: 'high',
};
exports.BotEmpathyLevel = {
    None: 'none',
    Low: 'low',
    Medium: 'medium',
    High: 'high',
};
exports.BotResponseScope = {
    RelatedTopics: 'Related Topics',
    KnowledgeBase: 'Knowledge Base',
    Greetings: 'Greetings',
    GeneralConversations: 'General Conversations',
};
exports.BotActivePeriod = {
    AllTime: 'all-time',
    Custom: 'custom',
};
exports.BotDelayUnit = {
    Seconds: 'seconds',
    Minutes: 'minutes',
};
