"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userAccountsListState = exports.botListState = exports.activeBotState = void 0;
var recoil_1 = require("recoil");
/**
 * Active Bot(AI User) State
 *
 * This atom is used to store the active bot
 */
exports.activeBotState = (0, recoil_1.atom)({
    key: 'activeBotState',
    default: null,
});
/**
 * Bot(AI User) List State
 *
 * This atom is used to store all the available bots
 */
exports.botListState = (0, recoil_1.atom)({
    key: 'botListState',
    default: [],
});
/**
 * AI User Account List State
 *
 * This atom is used to store the list of available AI user accounts
 */
exports.userAccountsListState = (0, recoil_1.atom)({
    key: 'userAccountsList',
    default: [],
});
