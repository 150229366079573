"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var IBotAccount_1 = require("@/dto/types/ai-users/IBotAccount");
var yup = __importStar(require("yup"));
/**
 * Bot Account Schema
 *
 * @description This schema is used to validate the bot account data when it is about to be activated or updated
 *
 */
var BotAccountSchema = yup
    .object()
    .shape({
    _id: yup.string().required('Bot ID is required.'),
    enabled: yup.boolean().required(),
    name: yup.string().required(),
    userId: yup.string().required('You need to select a user account.'),
    serverId: yup.string().required(),
    assignedChannels: yup
        .array()
        .of(yup.string().required())
        .required()
        .when('enabled', {
        is: true,
        then: function (s) { return s.min(1, 'At least one channel is required.'); },
    }),
    languageMode: yup.string().required(),
    language: yup.string().required(),
    formality: yup.string().required(),
    toneOfVoice: yup
        .array()
        .of(yup.string().required())
        .when('enabled', {
        is: true,
        then: function (s) { return s.min(1, 'At least one tone of voice is required.'); },
    })
        .required(),
    responseLength: yup
        .array()
        .of(yup.string().required())
        .required()
        .min(1, 'At least one response length is required.'),
    responseLengthMin: yup.number().required(),
    responseLengthMax: yup.number().required(),
    customResponseLength: yup.number(),
    personaProtection: yup.boolean().required(),
    humorLevel: yup.string(),
    empathyLevel: yup.string(),
    age: yup.number(),
    gender: yup.string(),
    location: yup.string(),
    hobbies: yup.string(),
    communityHistory: yup.string(),
    backgroundStory: yup.string(),
    // ---------- Conversational Settings ---------- //
    responseEnabled: yup.boolean().required(),
    responseScopes: yup
        .array()
        .of(yup.string().required())
        .required()
        .when(['enabled', 'responseEnabled'], {
        is: function (enabled, responseScopeEnabled) { return enabled && responseScopeEnabled; },
        then: function (s) { return s.min(1, 'At least one response scope is required.'); },
    }),
    responseWithMention: yup.boolean().required(),
    askClarifyingQuestions: yup.boolean().required(),
    askProbingQuestions: yup.boolean().required(),
    questionLimit: yup.number().required(),
    responseDelayFrom: yup.number(),
    responseDelayTo: yup.number(),
    // ---------- Active Period ---------- //
    activePeriod: yup.string().required(),
    activeDays: yup
        .array()
        .of(yup.string().required())
        .when(['enabled', 'activePeriod'], {
        is: function (enabled, activePeriod) { return enabled && activePeriod === IBotAccount_1.BotActivePeriod.Custom; },
        then: function (s) { return s.min(1, 'At least one active day is required'); },
    })
        .required(),
    activeTimeZone: yup.string().when(['enabled', 'activePeriod'], {
        is: function (enabled, activePeriod) { return enabled && activePeriod === IBotAccount_1.BotActivePeriod.Custom; },
        then: function (s) { return s.required(); },
    }),
    activeTimeFrom: yup.string().when(['enabled', 'activePeriod'], {
        is: function (enabled, activePeriod) { return enabled && activePeriod === IBotAccount_1.BotActivePeriod.Custom; },
        then: function (s) { return s.required(); },
    }),
    activeTimeTo: yup.string().when(['enabled', 'activePeriod'], {
        is: function (enabled, activePeriod) { return enabled && activePeriod === IBotAccount_1.BotActivePeriod.Custom; },
        then: function (s) { return s.required(); },
    }),
    communityDescription: yup.string().when('enabled', {
        is: true,
        then: function (s) { return s.required('Community description is required.'); },
    }),
    topicOfFocus: yup.string().when('enabled', {
        is: true,
        then: function (s) { return s.required('Topic of focus is required.'); },
    }),
    topicOfAvoid: yup.string().when('enabled', {
        is: true,
        then: function (s) { return s.required('Topic of avoid is required.'); },
    }),
    crawlFiles: yup.array().of(yup.mixed().required()).required(),
    knowledgeFiles: yup.array().of(yup.mixed().required()).required(),
    //------------ TODO: Add these fields ------------//
})
    .required();
exports.default = BotAccountSchema;
